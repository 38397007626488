import React, { Component } from "react";
import { getToken } from "../../utils/localStorageServices";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        <div class="footer-upper">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-lg-4">
                <div class="footer-content-box">
                  <Link to="/" class="footer-logo">
                    <h3>
                      The Goldn <span>link</span>
                    </h3>
                  </Link>
                  <p>
                    Welcome to THE GOLDNLink website where you can find all of
                    our curated content, community events, discussions boards,
                    members, and donate your time or money to uplift and inspire
                    women of color.
                  </p>
                  {!this.props.contact && (
                    <div
                      class="query-link"
                      data-bs-toggle="modal"
                      data-bs-target="#contact"
                    >
                      <p onClick={() => this.props.history.push("/contact")}>
                        <i class="fas fa-phone-alt"></i>Contact Us
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div class="col-md-6 col-lg-8 d-md-flex justify-content-md-end">
                <ul class="footer-nav">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/community-member">Community</Link>
                  </li>
                  <li>
                    <Link to="/forum">Forum</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  {getToken("refresh") ? (
                    <li>
                      <Link to="/dashboard">Profile</Link>
                    </li>
                  ) : (
                    <li>
                      <Link to="/sign-in">Log In</Link>
                    </li>
                  )}
                  <li>
                    <Link to="/donate">Donate</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-lower">
          <div class="container">
            <div class="row">
              <div class="col-md-12 text-center">
                <p>
                  &copy; 2019-{new Date().getFullYear()} All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
