import React, { Component, createRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import whiteCircle from "../../include/images/white-circle-bg.png";
import heroShape from "../../include/images/hero-shape.svg";
gsap.registerPlugin(ScrollTrigger);

class Hero extends Component {
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  animateRef3 = createRef(null);
  componentDidMount = () => {
    const animateRef = [this.animateRef1, this.animateRef2, this.animateRef3];
    animateRef.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
  };
  render() {
    return (
      <div class="hero-container d-flex align-items-center overflow-hidden">
        <link rel="stylesheet" href="../../Include/css/hero.css" />
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="hero-content-box text-center">
                <div
                  class="hero-bg-1 animate-child2"
                  ref={(el) => {
                    this.animateRef1 = el;
                  }}
                >
                  <img src={whiteCircle} alt="" />
                </div>
                <div
                  class="hero-bg-2 animate-child2"
                  ref={(el) => {
                    this.animateRef2 = el;
                  }}
                >
                  <img src={heroShape} alt="" />
                </div>
                <div
                  class="hero-text-box animate-child2"
                  ref={(el) => {
                    this.animateRef3 = el;
                  }}
                >
                  <h6 class="filled-text">Welcome to</h6>
                  <h1 class="outline-text">
                    <b>THE GOLDN</b> <span>link</span>
                  </h1>
                  <p>
                    We’re on a mission to uplift and inspire <span>Black</span>{" "}
                    and <span>Brown</span> women around the world.
                  </p>
                </div>
                <a href="#intro-container" class="scroll-down" address="true">
                  <span></span>
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
