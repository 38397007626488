export const mobileRequired = "Mobile Number field is required";
export const companyNameRequired = "Company Name field is required";
export const confirmPasswordRequired = "Confirm Password field is required";
export const confirmPasswordMatch =
    "Confirm Password and Password fields must be same";

export const mobileInvalid = "Mobile Number is invalid";
export const emailInvalid = "Email is invalid";
export const loginRequired = "Email ID / Mobile Number field is required";

export const emailUpdated = "Your email has been updated successfully";
export const mobileUpdated = "Your mobile has been updated successfully";

export const firstNameRequired = "First Name field is required";
export const lastNameRequired = "Last Name field is required";
export const nameRequired = "Name field is required";

export const emailRequired = "Email field is required";
export const codeRequired = "Code field is required";
export const oldPassworddRequired = "Old Password field is required";

export const passwordRequired = "Password field is required";
export const passwordInvalid = "Password is invalid";

export const passwordInvalidMax =
    "Password length cannot be more than 25 characters long.";

export const loginFailed = "Login failed!";
export const accountNotExist = "Sorry, this account does not exist.";

export const emailExist =
    "A user with this email already exists. Please use another email";




export const picsRequired = "Image field is required";




