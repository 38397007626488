import React, { Component, createRef } from "react";
import Footer from "../common/footer";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import SocialMedia from "../common/socialMedia";
import gsap from "gsap";
import UserCard from "./userCard";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addToRodolex,
  getProfile,
  getUser,
  searchUser,
} from "../../store/users";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import Loader from "../common/loader";
import search from "../../include/images/search.svg";
import closeIcon from "../../include/images/close-icon.svg";
import folderIcon from "../../include/images/folder-icon.svg";

class Rolodex extends Component {
  ref = createRef(null);
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  state = {
    menu: false,
    page: 1,
    keyword: "",
  };
  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    // const animateRef = [this.animateRef1, this.animateRef2];

    // animateRef.forEach(
    //   (cb) =>
    //     cb &&
    //     gsap.to(cb, {
    //       scrollTrigger: {
    //         trigger: cb,
    //         once: true,
    //         start: "top 90%",
    //         toggleClass: "animated",
    //       },
    //     })
    // );

    this.props.searchUser({ rolodex: true });
    this.props.getProfile();
  };
  handlePageClick = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.setState({ page: e.selected + 1 }, () =>
      this.props.searchUser({ page: this.state.page })
    );
  };

  handleRobolex = (e, id, data) => {
    this.setState({ loading: true, rolodex: data });
    if (e) e.preventDefault();
    this.props.addToRodolex(id, data, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.getProfile();
      this.props.searchUser({ page: this.state.page, rolodex: true });
      if (this.state.rolodex) {
        toast(<AlertSuccess message={"Added to Rolodex"} />);
      } else {
        toast(<AlertSuccess message={"Removed from Rolodex"} />);
      }
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  render() {
    return (
      <>
        <Loader laoderRef={this.ref} />
        <Header menu={this.state.menu} toggleMenu={this.toggleMenu} />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="rodolex-container">
          <div class="container container1">
            {this.props.users && this.props.users.length > 0 ? (
              <div class="row">
                <div class="col-lg-12">
                  <div class="header-with-search-box d-flex align-items-center">
                    <div class="heading">
                      <h3 class="mb-0">Rolodex</h3>
                    </div>
                    <div class="search-box ms-auto">
                      <div class="input-group flex-nowrap clearable">
                        <span class="input-group-text" id="addon-wrapping">
                          <img src={search} alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search User"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={this.state.keyword}
                          onChange={(e) =>
                            this.setState({ keyword: e.target.value }, () =>
                              this.props.searchUser({
                                keyword: this.state.keyword,
                                rolodex: true,
                              })
                            )
                          }
                        />
                        {this.state.keyword && (
                          <i
                            class="clearable__clear"
                            onClick={() =>
                              this.setState({ keyword: "" }, () =>
                                this.props.searchUser({
                                  keyword: this.state.keyword,
                                  rolodex: true,
                                })
                              )
                            }
                          >
                            <img src={closeIcon} alt="" />
                          </i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="rodolex-main-container">
                    <ul class="rodolex-list">
                      {this.props.users &&
                        this.props.users.length > 0 &&
                        this.props.users.map((el) => (
                          <UserCard
                            data={el}
                            profile={this.props.profile}
                            handleRobolex={this.handleRobolex}
                          />
                        ))}
                    </ul>
                    <ReactPaginate
                      forcePage={this.state.page - 1}
                      pageClassName={""}
                      pageLinkClassName={""}
                      previousClassName={"cm-prev-btn"}
                      nextClassName={"cm-next-btn"}
                      previousLinkClassName={"btn btn-gray"}
                      nextLinkClassName={"btn btn-outline"}
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.pagination.total_page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "cm-pagination d-flex justify-content-between"
                      }
                      activeLinkClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div class="row">
                  <div
                    class="col-lg-12"
                    // ref={(el) => (this.animateRef1 = el)}
                  >
                    <div class="header-with-search-box d-flex align-items-center">
                      <div class="heading">
                        <h3 class="mb-0">Rodolex</h3>
                      </div>
                      <div class="search-box ms-auto">
                        <div class="input-group flex-nowrap clearable">
                          <span class="input-group-text" id="addon-wrapping">
                            <img src={search} alt="" />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search User"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            value={this.state.keyword}
                            onChange={(e) =>
                              this.setState({ keyword: e.target.value }, () =>
                                this.props.searchUser({
                                  keyword: this.state.keyword,
                                  rolodex: true,
                                })
                              )
                            }
                          />
                          <i
                            class="clearable__clear"
                            onClick={() =>
                              this.setState({ keyword: "" }, () =>
                                this.props.searchUser({
                                  keyword: this.state.keyword,
                                  rolodex: true,
                                })
                              )
                            }
                          >
                            <img src={closeIcon} alt="" />
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!this.props.loading && (
                  <div class="row">
                    <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                      <div
                        class="cm-no-found"
                      >
                        <div class="no-found-icon">
                          <img src={folderIcon} alt="" />
                        </div>
                        <h3>No Information found!</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Aenean fermentum, nec dis elementum id id.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <SocialMedia />
        <Footer history={this.props.history} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: getUser(state).users,
  pagination: getUser(state).pagination,
  profile: getUser(state).profile,
  loading: getUser(state).loading,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  searchUser: (param, callback) => dispatch(searchUser(param, callback)),
  addToRodolex: (id, data, callback) =>
    dispatch(addToRodolex(id, data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Rolodex)
);
