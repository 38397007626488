import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import playBtn from "../../include/images/play-btn.svg";
gsap.registerPlugin(ScrollTrigger);

class VideoCont extends Component {
  state = {
    vid: false,
  };
  animate1 = React.createRef(null);
  animate2 = React.createRef(null);
  componentDidMount = () => {
    gsap.to(this.animate1, {
      scrollTrigger: {
        trigger: this.animate1,
        once: true,
        start: "top 90%",
        toggleClass: "animated",
      },
    });
    gsap.to(this.animate2, {
      scrollTrigger: {
        trigger: this.animate2,
        once: true,
        start: "top 90%",
        toggleClass: "animated",
      },
    });
  };

  playPause = () => {
    const myVideo = document.getElementById("video1");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  };

  render() {
    return (
      <div
        class="content-container intro-container overflow-hidden"
        id="intro-container"
      >
        <div class="container">
          <div class="row d-lg-flex align-items-lg-center">
            <div class="col-lg-4">
              <div class="intro-content-box">
                <div
                  class="heading animate-child"
                  ref={(el) => {
                    this.animate1 = el;
                  }}
                >
                  <h3>
                    Mission <span>Statement</span>
                  </h3>
                  <p>
                    To Uplift and Inspire Women of Color (WOC) to lead exemplary
                    lives through community, professionalism, and self-esteem.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div
                class="intro-img-box animate-child"
                ref={(el) => (this.animate2 = el)}
              >
                <div class="intro-img">
                  <div class="boxes box1"></div>
                  <div
                    class="ratio ratio-4x3 intro-imgage"
                    onClick={() => {
                      this.setState({ vid: !this.state.vid });
                      this.playPause();
                    }}
                  >
                    <video
                      width="320"
                      height="240"
                      controls={false}
                      id="video1"
                    >
                      <source
                        src={
                          process.env.REACT_APP_CDN +
                          "frontend/Introduction.mp4"
                        }
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                    {!this.state.vid && (
                      <div
                        class="play-btn"
                     
                      >
                        <img src={playBtn} alt="" />
                      </div>
                    )}
                   
                  </div>
             
                  <div class="boxes box2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoCont;
