import React, { Component } from "react";
import { Link } from "react-router-dom";
import { clearAllToken } from "../../utils/localStorageServices";
import { getToken } from "../../utils/localStorageServices";
import Logo from "../../include/images/logo.png";

class Header extends Component {
  logout = () => {};
  render() {
    return (
      <>
        <header id="header">
          <nav class="navbar">
            <div class="container">
              <div class="nav-inside d-flex align-items-center justify-content-between">
                <div class="navbar-logo-area">
                  <button
                    class="nav-icon"
                    id="navbarSideCollapse"
                    onClick={this.props.toggleMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>{" "}
                  Menu
                </div>
                {this.props.logo && (
                  <div class="header-logo">
                    <Link class="navbar-brand" to="/">
                      <img src={Logo} alt="" />
                    </Link>
                  </div>
                )}
                {getToken("refresh") ? (
                  <div class="header-right d-flex align-items-center">
                    <Link to="/dashboard" class="header-link">
                      <b>Dashboard</b>
                    </Link>
                    <a
                      href="javascript:void(0)"
                      class="header-link"
                      onClick={(e) => {
                        e.preventDefault();
                        clearAllToken();
                        window.location.href = "/sign-in";
                      }}
                    >
                      Logout
                    </a>
                    <Link to="/donate" class="header-btn d-lg-block d-none">
                      Donate
                    </Link>
                  </div>
                ) : (
                  <div class="header-right d-flex align-items-center">
                    <Link to="/sign-up" class="header-link">
                      <b>Become a Member</b>
                      <span>Signup</span>
                    </Link>
                    <Link to="/sign-in" class="header-link">
                      Login
                    </Link>
                    <Link to="/donate" class="header-btn d-md-block d-none">
                      Donate
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </nav>
        </header>
        <div class="overlay" onClick={this.props.toggleMenu} id="overlay"></div>
      </>
    );
  }
}

export default Header;
