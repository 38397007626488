import React, { Component, createRef } from "react";
import gsap from "gsap";
import bookwork from "../../include/images/bookwork-1.svg";
import bookmark from "../../include/images/bookmark.svg";
import memberIcon from "../../include/images/member-icon.jpg";

class UserCard extends Component {
  animateRef1 = createRef(null);
  componentDidMount = () => {
    gsap.to(this.animateRef1, {
      scrollTrigger: {
        trigger: this.animateRef1,
        once: true,
        start: "top 90%",
        toggleClass: "animated",
      },
    });
  };
  render() {
    const data = this.props.data;
    const profile = this.props.profile;
    return (
      <li
        class="rodolex-item  animate-child"
        ref={(el) => (this.animateRef1 = el)}
      >
        <div class="cm-box cm-modal-content">
          <div class="cm-head">
            <p>{data.firstName + " " + data.lastName}</p>
            <div class="bookmark-icon">
              <a
                href="#"
                title={
                  profile &&
                  profile.robolex &&
                  profile.robolex.find((id) => data._id === id)
                    ? "Remove from Rolodex"
                    : "Add to Rolodex"
                }
                class="cm-tooltip"
                onClick={(event) =>
                  this.props.handleRobolex(
                    event,
                    data._id,
                    profile &&
                      profile.robolex &&
                      profile.robolex.find((id) => data._id === id)
                      ? false
                      : true
                  )
                }
              >
                <img
                  src={
                    profile &&
                    profile.robolex &&
                    profile.robolex.find((id) => data._id === id)
                      ? bookwork
                      : bookmark
                  }
                  alt=""
                />
              </a>
            </div>
          </div>
          <div class="cm-body">
            <div class="cm-body-content d-flex flex-wrap">
              <div class="cm-member-left">
                <div class="member-img">
                  <img
                    src={
                      data && data.profilePicture
                        ? process.env.REACT_APP_CDN + data.profilePicture.small
                        : memberIcon
                    }
                    alt=""
                  />
                </div>
                <p class="cm-space">{data.location}</p>
                <h5>{data.firstName + " " + data.lastName}</h5>
                <p class="cm-profile">{data.profession}</p>
                <ul class="cm-link-list">
                  <li>
                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </li>
                  <li>
                    <a href={`tel:${data.mobile}`}>{data.mobile}</a>
                  </li>
                </ul>
                <div class="cm-footer">
                  <ul class="social-nav d-flex flex-wrap justify-content-center">
                    {data.socialMediaLinks.facebook && (
                      <li>
                        <a
                          href={data.socialMediaLinks.facebook}
                          target="_blank"
                        >
                          <i class="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    )}
                    {data.socialMediaLinks.twitter && (
                      <li>
                        <a href={data.socialMediaLinks.twitter} target="_blank">
                          <i class="fab fa-twitter"></i>
                        </a>
                      </li>
                    )}
                    {data.socialMediaLinks.instagram && (
                      <li>
                        <a
                          href={data.socialMediaLinks.instagram}
                          target="_blank"
                        >
                          <i class="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div class="cm-member-right">
                <div class="member-info">
                  <h5>Passion</h5>
                  <ul class="passion-list d-flex flex-wrap">
                    {data.passions &&
                      data.passions.length > 0 &&
                      data.passions.map((p) => <li>{p}</li>)}
                  </ul>
                  <h5>Bio</h5>
                  {data.bio &&
                    data.bio.split("\n").map((el, i) => <p key={i}>{el}</p>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default UserCard;
