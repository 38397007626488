import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { contactUs } from "../../store/misc";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";

class ContactModal extends Form {
  state = {
    loading: false,
    data: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      reason: "",
    },
    errors: {},
  };

  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: "First Name is required" };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: "Last Name is required" };
      }),

    reason: Joi.string()
      .required()
      .label("Reason")
      .error(() => {
        return { message: "Reason is required" };
      }),
    mobile: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = "Phone Number is required";
              break;

            case "number.max":
              err.message = "Phone Number is Invalid";
              break;

            case "number.min":
              err.message = "Phone Number is invalid";
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required";
              break;
            case "string.email":
              err.message = "Email is invalid";
              break;
            case "string.max":
              err.message = "Email is invalid";
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    let formData = {
      fullName: data.firstName + " " + data.lastName,
      email: data.email,
      message: data.email,
      phone: data.mobile,
    };
    this.props.contact(formData, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      this.props.toggleModal();
      toast(<AlertSuccess message={"Information Saved"} />);
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.contactUs
              ? "modal fade custom-modal show d-block"
              : "modal fade custom-modal d-none"
          }
          id="contact"
          tabIndex={-1}
          aria-labelledby="contact"
          aria-hidden={this.props.contactUs ? "false" : "true"}
          role="dialog"
          aria-modal={this.props.contactUs ? "true" : "false"}
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header text-center d-flex justify-content-center align-items-center">
                <h5>
                  Contact <span>Us</span>
                </h5>
                <button
                  onClick={() => {
                    this.props.toggleModal();
                  }}
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form class="contact-form" onSubmit={this.handleSubmit}>
                  <div class="row">
                    <div class="col-md-6">
                      {this.renderInput("firstName", "First Name")}
                    </div>
                    <div class="col-md-6">
                      {this.renderInput("lastName", "Last Name")}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      {this.renderInput("email", "Email Address", "email")}
                    </div>
                    <div class="col-md-6">
                      {this.renderInput("mobile", "Phone Number")}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      {this.renderTextarea("reason", "Reason for the Contact")}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 ms-auto">
                      <SubmitBtn
                        btnClass="btn btn-primary btn-block"
                        label="Send"
                        loading={this.state.loading}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {this.props.contactUs && <div class="modal-backdrop fade show"></div>}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  contact: (data, callback) => dispatch(contactUs(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ContactModal));
