import React from "react";
import { Link } from "react-router-dom";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import { forgotPassword } from "../../store/users";
import lineCircle from "../../include/images/line-circle.png";
import memberBg from "../../include/images/member-bg.png";

class ForgotPassword extends Form {
  state = {
    data: {
      email: "",
    },
    errors: {},
  };
  schema = {
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required";
              break;
            case "string.email":
              err.message = "Email is invalid";
              break;
            case "string.max":
              err.message = "Email is invalid";
              break;
          }
        });
        return errors;
      }),
  };
  componentDidMount = () => {
    document.body.classList.add("p-0");
  };
  componentWillUnmount = () => {
    document.body.classList.remove("p-0");
  };

  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    this.props.forgotPassword(data, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.history.push({
        pathname: "/reset-password",
        state: {
          id: res.data.data.code,
        },
      });
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    return (
      <div class="member-container d-lg-flex">
        <div class="member-left">
          <div class="member-bg">
            <img src={memberBg} alt="" />
            <div class="circle-img">
              <img src={lineCircle} alt="" />
            </div>
          </div>
          <div class="member-content-box">
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              Welcome to
            </h6>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              THE GOLDN <span>link</span>
            </h3>
            <p>
              We’re on a mission to empower <span>Black</span> and{" "}
              <span>Brown</span> women around the world and help them build the
              necessary connections to change their circumstances.
            </p>
          </div>
        </div>
        <div class="member-right">
          <div class="member-form-container d-flex align-items-center justify-content-center">
            <div class="member-form-box">
              <h1>Forgot password</h1>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("email", "Email Address", "email")}
                <div class="form-group">
                  <input
                    type="submit"
                    name="Login"
                    class="btn btn-primary btn-block "
                    value="Send"
                  />
                </div>
              </form>
              <div class="already-member text-center">
                <p>
                  Go back to <Link to="/sign-in">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email, callback) =>
    dispatch(forgotPassword(email, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));
