import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { resendOtp, verifyEmail } from "../../store/users";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import _ from "lodash";
import { setToken } from "../../utils/localStorageServices";
import lineCircle from "../../include/images/line-circle.png";
import memberBg from "../../include/images/member-bg.png";

class VerifyOtp extends Component {
  state = {
    otpToShow: JSON.parse(localStorage.getItem("otp")),
    countDown: 59,
    reset: false,
    countDownId: 1,
    otp: {
      a: "",
      b: "",
      c: "",
      d: "",
    },
    disabledbutton: true,
  };
  aRef = React.createRef();
  bRef = React.createRef();
  cRef = React.createRef();
  dRef = React.createRef();

  componentDidMount = () => {
    setTimeout(
      () =>
        toast(
          <AlertSuccess
            message={this.state.otpToShow && this.state.otpToShow.otpKey}
          />
        ),
      1000
    );
    document.body.classList.add("p-0");
    const countDown = setInterval(() => {
      if (this.state.countDown === 1 || this.state.reset)
        clearInterval(countDown);
      this.setState({ countDown: this.state.countDown - 1 });
    }, 1000);
    this.setState({ countDownId: countDown });
  };

  componentWillUnmount() {
    clearInterval(this.state.countDownId);
    document.body.classList.remove("p-0");
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const otp = this.state.otp;

    otp[name] = value.replace(/\D/, "");
    if (otp[name] !== "") {
      this.setState({ otp });
      if (name === "a") this.bRef.current.focus();
      if (name === "b") this.cRef.current.focus();
      if (name === "c") this.dRef.current.focus();
    } else {
      this.setState({ otp });
    }
    if (otp.a && otp.b && otp.c && otp.d) {
      this.setState({ disabledbutton: false });
    } else if (!otp.a || !otp.b || !otp.c || !otp.d) {
      this.setState({ disabledbutton: true });
    }
  };

  doSubmit = (e) => {
    if (e) e.preventDefault();
    const { a, b, c, d } = this.state.otp;
    const otp = {
      otp: a + b + c + d,
    };
    this.props.verifyEmail(otp, this.callback);
  };

  callback = (res) => {
    if (res && res.status === 200) {
      this.props.history.push("/dashboard");
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };
  reSend = (e) => {
    this.props.resendOtp(this.callbackotp);
    e.preventDefault();
  };
  callbackotp = (res) => {
    if (res && res.status === 200) {
      localStorage.setItem("otp", JSON.stringify(res.data.data.otp));
      toast(<AlertSuccess message={res.data.data.otp.otpKey} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    var mail =
      localStorage.getItem("profile") &&
      JSON.parse(localStorage.getItem("profile")).email.split("@");
    const mail0Length = mail[0].length / 2;
    var replced = mail[0].split("");
    for (let i = Math.floor(mail0Length); i < mail[0].length; i++) {
      replced[i] = "x";
    }
    const mailToShow = replced.join("") + "@" + mail[1];
    return (
      <div class="member-container d-lg-flex">
        <div class="member-left">
          <div class="member-bg">
            <img src={memberBg} alt="" />
            <div class="circle-img">
              <img src={lineCircle} alt="" />
            </div>
          </div>
          <div class="member-content-box">
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              Welcome to
            </h6>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              THE GOLDN <span>link</span>
            </h3>
            <p>
              We’re on a mission to empower <span>Black</span> and{" "}
              <span>Brown</span> women around the world and help them build the
              necessary connections to change their circumstances.
            </p>
          </div>
        </div>
        <div class="member-right">
          <div class="member-form-container d-flex align-items-center justify-content-center">
            <div class="member-form-box">
              <h1>Enter Verification Code</h1>
              <p>
                Verification code has been sent to you email address (
                <em>{mailToShow}</em>)
              </p>
              <form onSubmit={this.doSubmit}>
                <div class="form-group">
                  <label for="email" class="form-label">
                    Enter Verification Code
                  </label>
                  <div class="form-group otp-box">
                    <input
                      class="form-control2"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      pattern="[0-9]{1}"
                      id="codeBox1"
                      type="text"
                      name="a"
                      value={this.state.otp.a}
                      onChange={this.onChange}
                      ref={this.aRef}
                    />
                    <input
                      class="form-control2"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      pattern="[0-9]{1}"
                      id="codeBox2"
                      type="text"
                      name="b"
                      value={this.state.otp.b}
                      onChange={this.onChange}
                      ref={this.bRef}
                    />
                    <input
                      class="form-control2"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      pattern="[0-9]{1}"
                      id="codeBox3"
                      type="text"
                      name="c"
                      value={this.state.otp.c}
                      onChange={this.onChange}
                      ref={this.cRef}
                    />
                    <input
                      class="form-control2"
                      maxLength="1"
                      size="1"
                      min="0"
                      max="9"
                      pattern="[0-9]{1}"
                      id="codeBox4"
                      type="text"
                      name="d"
                      value={this.state.otp.d}
                      onChange={this.onChange}
                      ref={this.dRef}
                    />
                  </div>
                </div>
                <div class="form-group form-button">
                  <input
                    type="submit"
                    name="Login"
                    class={
                      this.state.disabledbutton
                        ? "btn btn-primary disabled btn-block "
                        : "btn btn-primary btn-block"
                    }
                    value="Send"
                  />
                </div>
              </form>
              <div class="already-member text-center mt-3">
                <p>
                  Didn't get the code?{" "}
                  <a href="javascript:void(0)" onClick={(e) => this.reSend(e)}>
                    Resend
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (data, callback) => dispatch(verifyEmail(data, callback)),
  resendOtp: (callback) => dispatch(resendOtp(callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(VerifyOtp));
