import React, { Component, createRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import goldnlinkLogo from "../../include/images/goldnlink-logo.png";
import instagram from "../../include/images/instagram.png";
import facebook from "../../include/images/facebook.png";
import youtube from "../../include/images/youtube.png";
import { getMediaUrl, getMisc } from "../../store/misc";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class SocialMedia extends Component {
  animate1 = createRef(null);
  animate12 = createRef(null);

  componentDidMount = () => {
    const refAnimate = [this.animate1, this.animate2];
    refAnimate.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
    this.props.getMediaUrl();
  };
  render() {
    const media = this.props.media;
    return (
      <div class="bottom-block-container overflow-hidden">
        <div class="bb-upper">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 text-center animate-child"
                ref={(el) => {
                  this.animate1 = el;
                }}
              >
                <h2>Social Media Links</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="bb-lower">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <ul
                  class="social-media-list d-flex flex-wrap animate-child"
                  ref={(el) => {
                    this.animate1 = el;
                  }}
                >
                  <li class="sml-item">
                    <Link to="/" class="sml-box">
                      <div class="sml-logo">
                        <img src={goldnlinkLogo} alt="" />
                      </div>
                    </Link>
                  </li>
                  <li class="sml-item">
                    <a
                      href={media && media.instagram}
                      target="_blank"
                      class="sml-box"
                    >
                      <div class="sml-icon">
                        <img src={instagram} alt="" />
                      </div>
                      <p>Goldnlink.inc</p>
                    </a>
                  </li>
                  <li class="sml-item">
                    <a
                      href={media && media.facebook}
                      target="_blank"
                      class="sml-box"
                    >
                      <div class="sml-icon">
                        <img src={facebook} alt="" />
                      </div>
                      <p>Goldnlink.inc</p>
                    </a>
                  </li>
                  <li class="sml-item">
                    <a
                      href={media && media.youtube}
                      target="_blank"
                      class="sml-box"
                    >
                      <div class="sml-icon">
                        <img src={youtube} alt="" />
                      </div>
                      <p>Goldnlink.inc</p>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  media: getMisc(state).media,
});

const mapDispatchToProps = (dispatch) => ({
  getMediaUrl: () => dispatch(getMediaUrl()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SocialMedia)
);
