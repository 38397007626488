import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Form from "./form/form";
import Header from "./header";
import Sidebar from "./sidebar";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";
import { gsap } from "gsap";
import SeeYourPower from "./seeYourPower";
import Footer from "./footer";
import SubmitBtn from "./form/submitBtn";
import { contactUs } from "../../store/misc";
import halfCirlce from "../../include/images/half-circle.png";
import mobContact from "../../include/images/mob-contact-shape.png";

class ContactUs extends Form {
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  state = {
    menu: false,
    loading: false,
    data: {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      reason: "",
    },
    errors: {},
  };

  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    const animateRef = [this.animateRef1, this.animateRef2];
    animateRef.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
  };

  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: "First Name is required" };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: "Last Name is required" };
      }),

    reason: Joi.string()
      .required()
      .label("Reason")
      .error(() => {
        return { message: "Reason is required" };
      }),
    mobile: Joi.number()
      .required()
      .label("Mobile number")
      .min(1000000000)
      .max(999999999999999)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "number.base":
              err.message = "Phone Number is required";
              break;

            case "number.max":
              err.message = "Phone Number is Invalid";
              break;

            case "number.min":
              err.message = "Phone Number is invalid";
              break;
          }
        });
        return errors;
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required";
              break;
            case "string.email":
              err.message = "Email is invalid";
              break;
            case "string.max":
              err.message = "Email is invalid";
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    let formData = {
      fullName: data.firstName + " " + data.lastName,
      email: data.email,
      message: data.reason,
      phone: data.mobile,
    };
    this.props.contact(formData, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      this.props.toggleModal();
      toast(<AlertSuccess message={"Information Saved"} />);
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  render() {
    return (
      <>
        <Header
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true}
        />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="contact-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12 d-lg-flex flex-lg-wrap">
                <div class="contact-left">
                  <div
                    class="contact-text-box animate-child"
                    ref={(el) => (this.animateRef1 = el)}
                  >
                    <h3>Contact us</h3>
                    <p>
                      We are here for our GOLDN Family! Please fill out the
                      necessary info so that we can contact and assist you with
                      anything you need.
                    </p>
                  </div>
                </div>
                <div class="contact-right">
                  <div class="contact-img">
                    <img src={halfCirlce} alt="" />
                  </div>
                  <div class="half-radius-circle">
                    <div class="half-circle-img d-block d-lg-none">
                      <img src={mobContact} alt="" />
                    </div>
                  </div>
                  <form
                    onSubmit={this.handleSubmit}
                    class="contact-form-box animate-child"
                    ref={(el) => (this.animateRef2 = el)}
                  >
                    {this.renderInput("firstName", "First Name")}
                    {this.renderInput("lastName", "Last Name")}
                    {this.renderInput("email", "Email Address", "email")}
                    {this.renderInput("mobile", "Phone Number")}
                    {this.renderTextarea("reason", "Reason for the Contact")}
                    <div class="form-btn">
                      <SubmitBtn
                        btnClass="btn btn-primary btn-block"
                        label="Send"
                        loading={this.state.loading}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeeYourPower />
        <Footer contact={true} history={this.props.history} />
        <div class="overlay" onClick={this.toggleMenu} id="overlay"></div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  contact: (data, callback) => dispatch(contactUs(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ContactUs));
