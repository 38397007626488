import React, { createRef } from "react";
import Form from "../common/form/form";
import Joi from "joi-browser";
import SubmitBtn from "../common/form/submitBtn";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addEvent,
  editEvent,
  searchEvents,
  uploadCoverImage,
} from "../../store/events";
import moment from "moment";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import Loader from "../common/loader";
import _ from "lodash";
import close from "../../include/images/close.svg";

class AddEvent extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    type: "",
    reccuringType: "none",
    image: {},
    data: {
      title: "",
      description: "",
      startDate: "",
      startTime: "",
      endTime: "",
      endDate: "",
    },
    browserLabel: "Browse",
    disable: false,
    errors: {},
    imageName: "",
  };

  clearData = () => {
    this.setState({
      loading: false,
      data: {
        title: "",
        startDate: new Date(),
        startTime: "",
        endDate: "",
        endTime: "",
        description: "",
      },

      errors: { image: "", type: "" },
      image: {},
      type: "",
      reccuringType: "none",
    });
  };

  schema = {
    title: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Event Title is required.";
              break;
          }
        });
        return errors;
      }),
    startDate: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Start Date is required.";
              break;
            case "date.base":
              err.message = "Start Date is required.";
              break;
          }
        });
        return errors;
      }),
    startTime: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Start Time is required.";
              break;
            case "date.base":
              err.message = "Start Time is required.";
              break;
          }
        });
        return errors;
      }),
    endDate: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "End Date is required.";
              break;
            case "date.base":
              err.message = "End Date is required.";
              break;
          }
        });
        return errors;
      }),
    endTime: Joi.date()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "End Time is required.";
              break;
            case "date.base":
              err.message = "End Time is required.";
              break;
          }
        });
        return errors;
      }),

    description: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Description is required.";
              break;
          }
        });
        return errors;
      }),
  };

  uploadFile = async (e) => {
    this.ref.current.continuousStart();
    this.setState({
      loading: true,
      browserLabel: "Uploading...",
      disable: true,
    });
    this.setState({ imageName: e.target.files[0].name });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadCoverImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ image: res.data.data });
      delete errors.image;
      this.setState({
        loading: false,
        errors,
        browserLabel: "Browse",
        disable: false,
      });
      this.ref.current.complete();
    });
  };

  doSubmit = (e) => {
    this.setState({ loading: true });
    const { errors } = this.state;
    const data = { ...this.state.data };
    if (_.isEmpty(this.state.image)) {
      errors.image = "Cover Image is required";
    }
    this.setState({ errors, loading: false });
    let payload = {
      title: data.title,
      type: "non-recurring",
      description: data.description,
      recurringType:
        this.state.type === "recurring" ? this.state.reccuringType : "none",
      startDate: moment(
        moment(data.startDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
          " " +
          moment(data.startTime, "h:mm a").format("h:mm a"),
        "YYYY-MM-DD h:mm a"
      ).format("x"),
      endDate: moment(
        moment(data.endDate, "YYYY-MM-DD").format("YYYY-MM-DD") +
          " " +
          moment(data.endTime, "h:mm a").format("h:mm a"),
        "YYYY-MM-DD h:mm a"
      ).format("x"),
      coverImage: this.state.image,
    };
    if (_.isEmpty(this.state.errors)) {
      if (this.props.edit == true) {
        this.props.editEvent(
          this.props.editData && this.props.editData._id,
          payload,
          (res) => {
            this.setState({ loading: false });
            if (res && res.status === 200) {
              this.props.searchEvents({
                type: this.props.type,
                sort: this.props.sort,
                order: this.props.order,
              });
              this.props.toggleAddEvent();
              return toast(<AlertSuccess message={"Information Saved"} />);
            } else {
              return toast(
                <AlertError message={res && res.data && res.data.message} />
              );
            }
          }
        );
      } else {
        this.props.addEvent(payload, (res) => {
          this.setState({ loading: false });
          if (res && res.status === 200) {
            //this.clearData();
            // this.props.searchEvents();
            this.props.toggleAddEvent();
            return toast(<AlertSuccess message={"Information Saved"} />);
          } else {
            return toast(
              <AlertError message={res && res.data && res.data.message} />
            );
          }
        });
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.edit == false && this.props.edit !== prevProps.edit) {
      this.setState({
        loading: false,
        type: "",
        reccuringType: "none",
        image: {},
        data: {
          title: "",
          description: "",
          startDate: "",
          startTime: "",
          endTime: "",
          endDate: "",
        },
        browserLabel: "Browse",
        disable: false,
        errors: {},
      });
      this.props.setEditDataNull();
    }
    if (
      this.props.edit == true &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      const data = { ...this.state.data };
      let image = {};
      let type = "";
      let reccuringType = "";
      image = this.props.editData && this.props.editData.coverImage;
      type = this.props.editData && this.props.editData.type;
      reccuringType = this.props.editData && this.props.editData.recurringType;
      data.title = this.props.editData && this.props.editData.title;
      data.startDate = new Date(
        this.props.editData && this.props.editData.startDate
      );
      data.endDate = new Date(
        this.props.editData && this.props.editData.endDate
      );
      data.startTime = new Date(
        this.props.editData && this.props.editData.startDate
      );
      data.endTime = new Date(
        this.props.editData && this.props.editData.endDate
      );
      data.description = this.props.editData && this.props.editData.description;
      this.setState({ data, image, type, reccuringType });
    }
  };

  render() {
    const data = { ...this.state.data };

    return (
      <>
        <div
          class={
            this.props.addEventFlyer
              ? "modal right fade side-flyer-container show d-block"
              : "modal right fade side-flyer-container d-none"
          }
          id="addEvent"
          tabIndex={"-1"}
          aria-labelledby="addEventLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <Loader laoderRef={this.ref} />
            <form class="modal-content" onSubmit={this.handleSubmit}>
              <div class="dn-head d-flex align-items-center justify-content-between">
                <h5 class="modal-title" id="exampleModalLabel">
                  Add Event
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.toggleAddEvent}
                ></button>
              </div>
              <div class="dn-body ">
                {this.renderInput("title", "Event Title")}
                <div class="form-group">
                  <label for="formFile" class="form-label">
                    Select Image (Dimension should be approx 1250px * 500px)
                  </label>
                  <div class="upload-btn-wrapper d-flex justify-content-end">
                    <button style={{ cursor: "pointer" }} class="btn">
                      {this.state.browserLabel}
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      onChange={this.uploadFile}
                    />
                  </div>
                  {this.state.errors && this.state.errors.image && (
                    <label className="error">{this.state.errors.image}</label>
                  )}
                </div>
                {/* {!_.isEmpty(this.state.image) && (
                  <div class="form-group" style={{ width: "50%" }}>
                    <div class="ui-box" style={{ position: "relative" }}>
                      <div class="ui-img">
                        <img
                          src={
                            process.env.REACT_APP_CDN + this.state.image.small
                          }
                          
                          alt=""
                        />
                      </div>
                      <div
                        onClick={() => this.setState({ image: {} })}
                        class="close-img"
                        style={{
                          position: "absolute",
                          top: "-6%",
                          right: "-6%",
                          background: "#fff",
                          borderRadius: "50%",
                          height: "22px",
                          width: "22px",
                          verticalAlign: "center",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img src={close} alt="" />
                      </div>
                    </div>
                  </div>
                )} */}
                {!_.isEmpty(this.state.image) && (
                  <div class="form-group">
                    <ul class="upload-img-list d-flex flex-wrap">
                      <li>
                        <div class="ui-box">
                          <div class="ui-img">
                            <img
                              src={
                                process.env.REACT_APP_CDN +
                                this.state.image.small
                              }
                              alt=""
                            />
                          </div>
                          <div
                            class="close-img"
                            onClick={() => this.setState({ image: {} })}
                          >
                            <img src={close} alt="" />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}

                {/* <div class="form-group">
                    <label for="select" class="form-label">
                      Select Event Type
                    </label>
                    <select
                      class="form-select"
                      onChange={(e) => {
                        const errors = { ...this.state.errors };
                        e.preventDefault();
                        if (
                          e.target.value === "recurring" ||
                          e.target.value === "non-recurring"
                        ) {
                          this.setState({ type: e.target.value });
                          delete errors.type;
                          this.setState({ errors });
                        } else {
                          errors.type = "Event type is required";
                          this.setState({ errors });
                        }
                      }}
                    >
                      <option value="none" selected={this.state.type == "none"}>
                        Select event Type
                      </option>
                      <option
                        value="non-recurring"
                        selected={this.state.type == "non-recurring"}
                      >
                        Single Day Event
                      </option>
                      <option
                        value="recurring"
                        selected={this.state.type == "recurring"}
                      >
                        Recurring Event
                      </option>
                      <option value="3">Random Event</option> 
                    </select> 
                     {this.state.errors && this.state.errors.type && (
                      <label className="error">{this.state.errors.type}</label>
                    )}
                  </div> */}
                <div class="row">
                  <div class="col-md-6">
                    {this.renderDateInput(
                      "startDate",
                      "Start Date",
                      new Date()
                    )}
                  </div>
                  <div class="col-md-6">
                    {this.renderDateInput("endDate", "End Date", new Date())}
                  </div>
                </div>
                <div id="hidden_div2">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="select" class="form-label">
                          Year
                        </label>
                        <select class="form-select">
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="select" class="form-label">
                          Month
                        </label>
                        <select class="form-select">
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    {this.renderTimeInput("startTime", "Start Time")}
                  </div>
                  <div class="col-md-6">
                    {this.renderTimeInput("endTime", "End Time")}
                  </div>
                </div>
                <div
                  id="hidden_div"
                  class={this.state.type === "recurring" ? "d-block" : "d-none"}
                >
                  <div class="form-group">
                    <label for="select" class="form-label">
                      Recurring by
                    </label>
                    <select
                      class="form-select"
                      onChange={(e) => {
                        this.setState({
                          reccuringType: e.target.value,
                        });
                      }}
                    >
                      <option
                        value="none"
                        selected={this.state.reccuringType === "none"}
                      >
                        Select
                      </option>
                      <option
                        value="daily"
                        selected={this.state.reccuringType === "daily"}
                      >
                        Daily
                      </option>
                      <option
                        value="weekly"
                        selected={this.state.reccuringType === "weekly"}
                      >
                        Weekly
                      </option>
                      <option
                        value="monthly"
                        selected={this.state.reccuringType === "monthly"}
                      >
                        Monthly
                      </option>
                      <option
                        value="yearly"
                        selected={this.state.reccuringType === "yearly"}
                      >
                        Yearly
                      </option>
                    </select>
                  </div>
                </div>
                {this.renderTextarea("description", "Description")}
              </div>
              <div class="dn-footer">
                <SubmitBtn
                  btnClass="btn btn-primary btn-block"
                  label="Save"
                  loading={this.state.loading}
                  disabled={this.state.disable}
                />
              </div>
            </form>
          </div>
        </div>
        {this.props.addEventFlyer && (
          <div className="modal-backdrop fade show"></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uploadCoverImage: (data, callback) =>
    dispatch(uploadCoverImage(data, callback)),
  addEvent: (data, callBack) => dispatch(addEvent(data, callBack)),
  editEvent: (id, data, callBack) => dispatch(editEvent(id, data, callBack)),
  searchEvents: (data, callback) => dispatch(searchEvents(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(AddEvent));
