import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changePasswordUser } from "../../store/users";
import Form from "../common/form/form";
import Joi from "joi-browser";
import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
  confirmPasswordMatch,
  confirmPasswordRequired,
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import SubmitBtn from "../common/form/submitBtn";

class ChangePassword extends Form {
  state = {
    loading: false,
    data: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    errors: {},
  };

  schema = {
    oldPassword: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Old Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("New Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!" + confirmPasswordMatch,
            empty: "!!" + confirmPasswordRequired,
          },
        },
      }),
  };

  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    let formData = {
      currentPassword: data.oldPassword,
      newPassword: data.password,
    };
    this.props.changePasswordUser(formData, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      this.setState({
        loading: false,
        data: {
          oldPassword: "",
          password: "",
          confirmPassword: "",
        },
        errors: {},
      });
      this.props.toggleChangePassword();
      toast(<AlertSuccess message={"Password changed successfully"} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    return (
      <>
        <div
          class={
            this.props.changePassword
              ? "modal fade right dashboard-notification-modal show d-block"
              : "modal fade right dashboard-notification-modal d-none"
          }
          id="changePassword"
          tabIndex={"-1"}
          aria-labelledby="changePassword"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="dn-head d-flex align-items-center justify-content-between">
                <h5 class="modal-title" id="exampleModalLabel">
                  Change Password
                </h5>
                <button
                  onClick={() => {
                    this.setState({
                      loading: false,
                      data: {
                        oldPassword: "",
                        password: "",
                        confirmPassword: "",
                      },
                      errors: {},
                    });
                    this.props.toggleChangePassword();
                  }}
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="dn-body">
                {this.renderPasswordInput("oldPassword", "Old Password")}
                <div class="line"></div>
                {this.renderPasswordInput("password", "New Password")}
                {this.renderPasswordInput(
                  "confirmPassword",
                  "Confirm Password"
                )}
              </div>
              <div class="dn-footer">
                <SubmitBtn
                  btnClass={"btn btn-primary btn-block"}
                  onClickHandle={this.doSubmit}
                  loading={this.state.loading}
                  label="Save"
                />
              </div>
            </div>
          </div>
        </div>
        {this.props.changePassword && (
          <div class="modal-backdrop fade show"></div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changePasswordUser: (data, callback) =>
    dispatch(changePasswordUser(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(ChangePassword));
