import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getForum, getTopicDetail } from "../../store/forum";
import Sidebar from "../common/sidebar";
import Header from "../common/header";
import gsap from "gsap";
import moment from "moment";
import { createComment, getComment, getComments } from "../../store/comment";
import SubmitBtn from "../common/form/submitBtn";
import { getToken } from "../../utils/localStorageServices";
import { addToRodolex, getProfile, getUser } from "../../store/users";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import profile4 from "../../include/images/profile4.png";
import loadingIconGray from "../../include/images/loading-icon-gray.svg";
import CommunityPopup from "../community/communityPopup";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

class ForumDetail extends Component {
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  animateRef3 = createRef(null);
  animateRef4 = createRef(null);
  state = {
    menu: false,
    parentComment: "",
    comment: "",
    loading: false,
    loadingParent: false,
    disabled: false,
    disabledParent: false,
    reply: false,
    replyId: "",
    parentId: "",
    viewMore: false,
    detailPop: false,
    id: "",
    rolodex: false,
  };
  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    const animateRef = [
      this.animateRef1,
      this.animateRef2,
      this.animateRef3,
      this.animateRef4,
    ];
    animateRef.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.props.getTopicDetail(this.props.match.params.id);
      this.props.getComments(this.props.match.params.id);
    }
    if (getToken("refresh")) {
      this.props.getProfile();
    }
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  sendComment = (id) => {
    this.setState({ loading: true });
    const param = {
      comment: this.state.comment,
      forumTopicId: this.props.detail._id,
      parentId: this.state.parentId,
    };
    this.props.createComment(param, this.callbackComment);
  };

  sendCommentParent = (id) => {
    this.setState({ loadingParent: true });
    const param = {
      comment: this.state.parentComment,
      forumTopicId: this.props.detail._id,
    };
    this.props.createComment(param, this.callbackComment);
  };

  callbackComment = (res) => {
    this.setState({
      loading: false,
      loadingParent: false,
      disabled: false,
      disabledParent: false,
    });
    if (res && res.status == 200) {
      this.props.getComments(this.props.match.params.id);
      this.setState({ comment: "", parentComment: "", reply: false });
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };

  toggleModal = (id) => {
    this.setState({ detailPop: !this.state.detailPop, id }, () => {
      if (this.state.detailPop) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else if (!this.state.detailPop) {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  handleRobolex = (e, id, data) => {
    this.setState({ loading: true, rolodex: data });
    if (e) e.preventDefault();
    this.props.addToRodolex(id, data, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res && res.status === 200) {
      this.props.getProfile();
      //this.props.searchUser({ page: this.state.page });
      if (this.state.rolodex) {
        toast(<AlertSuccess message={"Added to Rolodex"} />);
      } else {
        toast(<AlertSuccess message={"Romoved from Rolodex"} />);
      }
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };
  render() {
    const detail = this.props.detail;
    const comment = this.props.comment;
    const profile = this.props.profile;
    return (
      <>
        <Header
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true}
        />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="forums-image-container">
          <div class="container">
            <div class="row">
              <div
                class="col-md-12 animate-child"
                ref={(el) => (this.animateRef1 = el)}
              >
                <div class="fic-box">
                  <div
                    class="fic-bg"
                    style={{
                      backgroundImage: `url(${
                        detail &&
                        detail.coverImage &&
                        process.env.REACT_APP_CDN + detail.coverImage.original
                      })`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="forums-details-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="forums-details-box d-md-flex flex-md-wrap">
                  <div
                    class="fd-sidebar  animate-child"
                    ref={(el) => (this.animateRef2 = el)}
                  >
                    <ul class="social-link d-flex justify-content-center align-items-center">
                      <li>
                        <FacebookShareButton
                          url={`${process.env.REACT_APP_BASEURL}forum-detail/${detail.slug}`}
                          quote={detail.name}
                          hashtag="#forum-topic"
                        >
                          <a href="#!">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={`${process.env.REACT_APP_BASEURL}forum-detail/${detail.slug}`}
                          title={detail.name}
                          hashtags={["forum-topic"]}
                        >
                          <a href="#!">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          url={`${process.env.REACT_APP_BASEURL}forum-detail/${detail.slug}`}
                          title={detail.name}
                          summary={detail.description}
                          source={`${process.env.REACT_APP_BASEURL}`}
                        >
                          <a href="#!">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </LinkedinShareButton>
                      </li>
                    </ul>
                    <div class="fd-comment-btn">
                      <a
                        href="#comment"
                        class={
                          detail.hostType === "admin"
                            ? "btn btn-sucess btn-block"
                            : "btn btn-sucess btn-block btn-comment"
                        }
                      >
                        Comments
                      </a>
                    </div>
                  </div>
                  <div
                    class="fd-main-content animate-child"
                    ref={(el) => (this.animateRef3 = el)}
                  >
                    <div
                      class="fd-main-heading"
                      style={{
                        color: detail.hostType === "admin" ? "#EBC7B0" : "#666",
                      }}
                    >
                      {detail.hostType === "admin"
                        ? "Goldnlink Forum"
                        : "Community Forum"}
                    </div>
                    <h3>{detail.name}</h3>
                    <h4>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (
                            getToken("refresh") &&
                            detail.hostType !== "admin"
                          ) {
                            this.toggleModal(detail.createdBy._id);
                          }
                        }}
                      >
                        By{" "}
                        {detail.hostType === "admin"
                          ? "Admin"
                          : detail.createdBy &&
                            detail.createdBy.firstName +
                              " " +
                              detail.createdBy.lastName}
                      </span>
                      {moment(detail.createdAt).fromNow()}
                    </h4>

                    {detail.longDescription &&
                      detail.longDescription
                        .split("\n")
                        .map((el, i) => <p key={i}>{el}</p>)}

                    {((detail &&
                      detail.video &&
                      detail.video.type === "youtube") ||
                      (detail &&
                        detail.video &&
                        detail.video.type === "vimeo")) && (
                      <a
                        href={detail && detail.video && detail.video.url}
                        target="_blank"
                        style={{ fontSize: "30px" }}
                      >
                        {detail &&
                        detail.video &&
                        detail.video.type === "youtube" ? (
                          <i class="fab fa-youtube"></i>
                        ) : detail &&
                          detail.video &&
                          detail.video.type === "vimeo" ? (
                          <i class="fab fa-vimeo"></i>
                        ) : (
                          ""
                        )}
                      </a>
                    )}
                    <h5>Tags</h5>
                    <div class="tag">
                      {detail.tags &&
                        detail.tags.map((el) => (
                          <span class="badge">{el}</span>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="comment-container" id="comment">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="comment-box animate-child"
                  ref={(el) => (this.animateRef4 = el)}
                >
                  <h5>Comments</h5>

                  <ul class="comment-list">
                    {!getToken("refresh") && (
                      <li class="comment">
                        <div class="comment-body d-flex align-items-center">
                          <div class="comment-author">
                            <img src={profile4} alt="" />
                          </div>
                          <div class="comment-content">
                            <div class="comment-form d-flex flex-wrap align-items-center justify-content-between">
                              <p class="login-post">Login to post a comment</p>
                              <a
                                href="javascript:void(0)"
                                class="btn btn-primary login"
                                onClick={() =>
                                  this.props.history.push({
                                    pathname: "/sign-in",
                                    state: {
                                      login: "login",
                                      id: this.props.match.params.id,
                                    },
                                  })
                                }
                              >
                                Login
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {getToken("refresh") && (
                      <li class="comment">
                        <div class="comment-body d-flex">
                          <div class="comment-author">
                            <img
                              style={{
                                cursor: getToken("refresh")
                                  ? "pointer"
                                  : "default",
                              }}
                              src={
                                profile &&
                                profile.profilePicture &&
                                process.env.REACT_APP_CDN +
                                  profile.profilePicture.small
                              }
                              alt=""
                              onClick={() => {
                                if (getToken("refresh")) {
                                  this.toggleModal(profile._id);
                                }
                              }}
                            />
                          </div>
                          <div class="comment-content">
                            <div class="comment-form">
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                placeholder="Write a comment"
                                value={this.state.parentComment}
                                onChange={(e) =>
                                  this.setState({
                                    parentComment: e.target.value,
                                  })
                                }
                              ></textarea>
                              <button
                                type="submit"
                                class="btn btn-primary btn-disabled"
                                disabled={
                                  this.state.disabledParent
                                    ? true
                                    : this.state.parentComment
                                    ? false
                                    : true
                                }
                                onClick={this.sendCommentParent}
                              >
                                {this.state.loadingParent ? (
                                  <b className="btn-loader">
                                    <img
                                      src={loadingIconGray}
                                      alt="loader"
                                      className="loader-img fa-spin"
                                      style={{ height: "15px" }}
                                    />
                                  </b>
                                ) : (
                                  "Send"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                    {comment && comment.length == 0 && (
                      <li class="comment">
                        <div class="comment-body d-flex">
                          <p class="first-comment">
                            Be the first one to comment
                          </p>
                        </div>
                      </li>
                    )}
                    {comment &&
                      comment.length > 0 &&
                      comment.map((c) => (
                        <li class="comment">
                          <div class="comment-body d-flex">
                            <div class="comment-author">
                              <img
                                style={{
                                  cursor: getToken("refresh")
                                    ? "pointer"
                                    : "default",
                                }}
                                src={
                                  c.createdBy.profilePicture &&
                                  process.env.REACT_APP_CDN +
                                    c.createdBy.profilePicture.small
                                }
                                alt=""
                                onClick={() => {
                                  if (getToken("refresh")) {
                                    this.toggleModal(c.createdBy._id);
                                  }
                                }}
                              />
                            </div>
                            <div class="comment-content">
                              <div class="comment-meta d-flex justify-content-between">
                                <span
                                  style={{
                                    cursor: getToken("refresh")
                                      ? "pointer"
                                      : "default",
                                  }}
                                  class="author"
                                  onClick={() => {
                                    if (getToken("refresh")) {
                                      this.toggleModal(c.createdBy._id);
                                    }
                                  }}
                                >
                                  {c.createdBy &&
                                    c.createdBy.firstName +
                                      " " +
                                      c.createdBy.lastName}
                                </span>
                                <a href="javascript:void(0)">
                                  {moment(c.createdAt).fromNow()}
                                </a>
                              </div>
                              <p>
                                {c.comment &&
                                  c.comment.split("\n").map((el) => el)}
                              </p>
                              <div class="reply d-flex justify-content-between">
                                {getToken("refresh") && (
                                  <a
                                    href="javascript:void(0)"
                                    class="comment-reply-link"
                                    onClick={() =>
                                      this.setState({
                                        reply: !this.state.reply,
                                        replyId: c._id,
                                        parentId: c._id,
                                      })
                                    }
                                  >
                                    Reply
                                  </a>
                                )}
                                {this.state.reply && (
                                  <a
                                    href="javascript:void(0)"
                                    class="comment-reply-link"
                                    onClick={() =>
                                      this.setState({
                                        reply: !this.state.reply,
                                        comment: "",
                                      })
                                    }
                                  >
                                    Cancel
                                  </a>
                                )}
                              </div>
                              {c.subComments && c.subComments.length > 0 && (
                                <ul class="reply-list">
                                  {c.subComments &&
                                  c.subComments.length > 0 &&
                                  !this.state.viewMore
                                    ? c.subComments.slice(0, 2).map((sc) => (
                                        <li>
                                          <div class="reply-body d-flex">
                                            <div class="reply-author">
                                              <img
                                                style={{
                                                  cursor: getToken("refresh")
                                                    ? "pointer"
                                                    : "default",
                                                  borderRadius: "50%",
                                                }}
                                                src={
                                                  sc.createdBy &&
                                                  sc.createdBy.profilePicture &&
                                                  process.env.REACT_APP_CDN +
                                                    sc.createdBy.profilePicture
                                                      .small
                                                }
                                                alt=""
                                                onClick={() => {
                                                  if (getToken("refresh")) {
                                                    this.toggleModal(
                                                      c.createdBy._id
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div class="reply-content">
                                              <div class="comment-meta d-flex justify-content-between">
                                                <span
                                                  class="author"
                                                  style={{
                                                    cursor: getToken("refresh")
                                                      ? "pointer"
                                                      : "default",
                                                  }}
                                                  onClick={() => {
                                                    if (getToken("refresh")) {
                                                      this.toggleModal(
                                                        c.createdBy._id
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {sc.createdBy &&
                                                    sc.createdBy.firstName +
                                                      " " +
                                                      sc.createdBy.lastName}
                                                </span>
                                                <a href="javascript:void(0)">
                                                  {moment(
                                                    sc.createdAt
                                                  ).fromNow()}
                                                </a>
                                              </div>
                                              <p>
                                                {sc.comment &&
                                                  sc.comment
                                                    .split("\n")
                                                    .map((el) => el)}
                                              </p>
                                              {/* <div class="reply">
                                              <a
                                                href="javascript:void(0)"
                                                class="comment-reply-link"
                                                onClick={() =>
                                                  this.setState({
                                                    reply: !this.state.reply,
                                                    replyId: c._id,
                                                    parentId: sc._id,
                                                  })
                                                }
                                              >
                                                Reply
                                              </a>
                                            </div> */}
                                            </div>
                                          </div>
                                        </li>
                                      ))
                                    : c.subComments.map((sc) => (
                                        <li>
                                          <div class="reply-body d-flex">
                                            <div class="reply-author">
                                              <img
                                                style={{ borderRadius: "50%" }}
                                                src={
                                                  sc.createdBy &&
                                                  sc.createdBy.profilePicture &&
                                                  process.env.REACT_APP_CDN +
                                                    sc.createdBy.profilePicture
                                                      .small
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div class="reply-content">
                                              <div class="comment-meta d-flex justify-content-between">
                                                <span class="author">
                                                  {sc.createdBy &&
                                                    sc.createdBy.firstName +
                                                      " " +
                                                      sc.createdBy.lastName}
                                                </span>
                                                <a href="javascript:void(0)">
                                                  {moment(
                                                    sc.createdAt
                                                  ).fromNow()}
                                                </a>
                                              </div>
                                              <p>
                                                {sc.comment &&
                                                  sc.comment
                                                    .split("\n")
                                                    .map((el) => el)}
                                              </p>
                                              {/* <div class="reply">
                                              <a
                                                href="javascript:void(0)"
                                                class="comment-reply-link"
                                                onClick={() =>
                                                  this.setState({
                                                    reply: !this.state.reply,
                                                    replyId: c._id,
                                                    parentId: sc._id,
                                                  })
                                                }
                                              >
                                                Reply
                                              </a>
                                            </div> */}
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                </ul>
                              )}
                              {c.subComments && c.subComments.length > 2 && (
                                <div class="view-reply">
                                  <a
                                    href="javascript:void(0)"
                                    class="view-reply-link"
                                    onClick={() =>
                                      this.setState({
                                        viewMore: !this.state.viewMore,
                                      })
                                    }
                                  >
                                    {this.state.viewMore
                                      ? "View less"
                                      : `View all
                                    ${c.subComments && c.subComments.length}
                                    Replies`}
                                  </a>
                                </div>
                              )}
                              {this.state.reply &&
                                this.state.replyId === c._id && (
                                  <div class="reply-form">
                                    <div class="comment-author">
                                      <img
                                        src={
                                          profile.profilePicture &&
                                          process.env.REACT_APP_CDN +
                                            profile.profilePicture.small
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div class="comment-form comment-reply">
                                      <textarea
                                        class="form-control"
                                        id="exampleFormControlTextarea1"
                                        placeholder="Write a reply"
                                        value={this.state.comment}
                                        onChange={(e) =>
                                          this.setState({
                                            comment: e.target.value,
                                          })
                                        }
                                      ></textarea>
                                      <button
                                        type="submit"
                                        class="btn btn-primary btn-disabled"
                                        disabled={
                                          this.state.disabled
                                            ? true
                                            : this.state.comment
                                            ? false
                                            : true
                                        }
                                        onClick={this.sendComment}
                                      >
                                        {this.state.loading ? (
                                          <b className="btn-loader">
                                            <img
                                              src={loadingIconGray}
                                              alt="loader"
                                              className="loader-img fa-spin"
                                              style={{ height: "15px" }}
                                            />
                                          </b>
                                        ) : (
                                          "Send"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CommunityPopup
          detailPop={this.state.detailPop}
          toggleModal={this.toggleModal}
          id={this.state.id}
          profile={this.props.profile}
          handleRobolex={this.handleRobolex}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: getForum(state).detail,
  comment: getComment(state).comment,
  profile: getUser(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  getTopicDetail: (id) => dispatch(getTopicDetail(id)),
  createComment: (data, callback) => dispatch(createComment(data, callback)),
  getComments: (id, param) => dispatch(getComments(id, param)),
  getProfile: () => dispatch(getProfile()),
  addToRodolex: (id, data, callback) =>
    dispatch(addToRodolex(id, data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForumDetail)
);
