import React, { Component } from "react";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
import Joi from "joi-browser";
import isURL from "validator/lib/isURL";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import _ from "lodash";
import CreatableSelect from "react-select/creatable";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile, updateProfileUser } from "../../store/users";
import ImageCropModel from "../common/imageCrooper";
import modalClose from "../../include/images/modal-close.svg";
import userPic from "../../include/images/user-pic.png";

class UpdateProfile extends Form {
  state = {
    image: "",
    cropper: false,
    loading: false,
    passions: [],
    profilePicture: {},
    data: {
      instagram: "",
      facebook: "",
      twitter: "",
      firstName: "",
      lastName: "",
      email: "",
      cellNo: "",
      whatYouDo: "",
      whereFrom: "",
      bio: "",
      hope: "",
      contribute: "",
    },
    errors: {},
  };

  uploadFile = async (e) => {
    const file = e.target.files[0];
    let image = await this.readFile(file);
    this.setState({ image, cropper: true });
  };
  toggleCrop = (e) => {
    if (e) e.preventDefault();
    this.setState({ cropper: !this.state.cropper });
  };
  readFile(file) {
    if (!file) return;
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: "First Name is required" };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: "Last Name is required" };
      }),
    cellNo: Joi.string().allow(null, ""),
    hope: Joi.string().allow(null, ""),
    contribute: Joi.string().allow(null, ""),
    whatYouDo: Joi.string().allow(null, ""),
    whereFrom: Joi.string().allow(null, ""),
    bio: Joi.string().allow(null, ""),
    twitter: Joi.string().allow(null, ""),
    facebook: Joi.string().allow(null, ""),
    instagram: Joi.string().allow(null, ""),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required";
              break;
            case "string.email":
              err.message = "Email is invalid";
              break;
            case "string.max":
              err.message = "Email is invalid";
              break;
          }
        });
        return errors;
      }),
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.updateProfile &&
      this.props.updateProfile !== prevProps.updateProfile
    ) {
      this.props.getProfile();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      const data = { ...this.state.data };
      const state = { ...this.state };
      data.firstName = this.props.profile.firstName;
      data.lastName = this.props.profile.lastName;
      data.email = this.props.profile.email;
      if (this.props.profile.mobile) {
        data.cellNo = this.props.profile.mobile;
      }
      if (this.props.profile.bio) {
        data.bio = this.props.profile.bio;
      }
      if (this.props.profile.hope) {
        data.hope = this.props.profile.hope;
      }
      if (this.props.profile.contribute) {
        data.contribute = this.props.profile.contribute;
      }
      if (this.props.profile.profession) {
        data.whatYouDo = this.props.profile.profession;
      }
      if (this.props.profile.location) {
        data.whereFrom = this.props.profile.location;
      }
      if (this.props.profile.socialMediaLinks) {
        data.instagram =
          this.props.profile.socialMediaLinks &&
          this.props.profile.socialMediaLinks.instagram;
        data.facebook =
          this.props.profile.socialMediaLinks &&
          this.props.profile.socialMediaLinks.facebook;
        data.twitter =
          this.props.profile.socialMediaLinks &&
          this.props.profile.socialMediaLinks.twitter;
      }
      if (
        this.props.profile.passions &&
        this.props.profile.passions.length > 0
      ) {
        let passions = [];
        passions =
          this.props.profile.passions &&
          this.props.profile.passions.map((e) => ({
            label: e,
            value: e,
          }));
        this.setState({ passions });
      }
      if (this.props.profile.profilePicture) {
        let profilePicture = {};
        profilePicture =
          this.props.profile && this.props.profile.profilePicture;

        this.setState({ profilePicture });
      }
      this.setState({ data });
    }
  };

  doSubmit = (e) => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if (!_.isEmpty(data.twitter) && !isURL(data.twitter)) {
      errors.twitter = "Please enter a valid URL";
    }
    if (!_.isEmpty(data.facebook) && !isURL(data.facebook)) {
      errors.facebook = "Please enter a valid URL";
    }
    if (!_.isEmpty(data.instagram) && !isURL(data.instagram)) {
      errors.instagram = "Please enter a valid URL";
    }
    if (!_.isEmpty(errors)) {
      return this.setState({ loading: false, errors });
    }
    if (
      !_.isEmpty(data.twitter) &&
      !isURL(data.twitter, { require_protocol: true })
    ) {
      data.twitter = "https://" + data.twitter;
    }
    if (
      !_.isEmpty(data.facebook) &&
      !isURL(data.facebook, { require_protocol: true })
    ) {
      data.facebook = "https://" + data.facebook;
    }
    if (
      !_.isEmpty(data.instagram) &&
      !isURL(data.instagram, { require_protocol: true })
    ) {
      data.instagram = "https://" + data.instagram;
    }
    let formData = {
      firstName: data.firstName,
      lastName: data.lastName,
      bio: data.bio,
      mobile: data.cellNo,
      location: data.whereFrom,
      profession: data.whatYouDo,
      hope: data.hope,
      contribute: data.contribute,
      socialMediaLinks: {
        facebook: data.facebook,
        twitter: data.twitter,
        instagram: data.instagram,
      },
      passions:
        this.state.passions && this.state.passions.map((el) => el.value),
    };
    this.props.updateProfileUser(formData, this.callBack);
  };

  callBack = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      this.props.getProfile();
      this.props.toggleProfile();
      toast(<AlertSuccess message={"Profile updated."} />);
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: "60px !important",
      position: "relative",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      border: "0",
      borderRadius: "0",
      backgroundColor: "#F5F5F5",
      borderColor: state.isFocused ? "#ebc7b0" : "#bdbdbd",
      boxShadow: "0 !important",
      "&:hover": {
        border: "1px solid #ebc7b0",
      },
      "&:focus": {
        border: "1px solid #ebc7b0",
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#000",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      ":hover": {
        backgroundColor: "#000",
        color: "white",
      },
    }),
  };

  render() {
    const components = {
      DropdownIndicator: null,
    };
    return (
      <>
        <div
          class={
            this.props.updateProfile
              ? "modal fade full-width-modal bg-white show d-block"
              : "modal fade full-width-modal bg-white"
          }
          id="updateProfile"
          tabindex="-1"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="fwm-content-row">
                <div class="fwm-head  w-100 d-flex flex-wrap position-relative">
                  <h4>Update Profile</h4>
                  <div
                    class="modal-close-btn"
                    data-bs-dismiss="modal"
                    onClick={this.props.toggleProfile}
                  >
                    <img src={modalClose} alt="" />
                  </div>
                </div>
                <div class="fwm-body">
                  <div class="fwm-row d-lg-flex flex-lg-wrap">
                    <div class="fwm-left-column w-md-50">
                      <div class="fwm-content-box">
                        <div class="form-group d-flex align-items-center">
                          <div
                            class="userpic"
                            style={{ width: "80px", height: "80px" }}
                          >
                            <img
                              style={{ borderRadius: "50%" }}
                              src={
                                this.props.profile &&
                                this.props.profile.profilePicture
                                  ? process.env.REACT_APP_CDN +
                                    this.props.profile.profilePicture.small
                                  : userPic
                              }
                            />
                          </div>
                          <div class="custom-file-uploader">
                            <input
                              type="file"
                              name="file"
                              onChange={this.uploadFile}
                              accept="image/*"
                            />
                            + Change Image
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            {this.renderInput("firstName", "First Name")}
                          </div>
                          <div class="col-md-6">
                            {this.renderInput("lastName", "Last Name")}
                          </div>
                        </div>
                        {this.renderInput(
                          "email",
                          "Email address",
                          "email",
                          true
                        )}
                        {this.renderInput(
                          "cellNo",
                          "Cell number with country code"
                        )}
                        {this.renderTextarea("whatYouDo", "What you do")}
                        {this.renderInput("whereFrom", "Where you’re from")}
                        <div class="form-group">
                          <label for="" class="form-label">
                            Passions
                          </label>
                          <CreatableSelect
                            components={components}
                            noOptionsMessage={() =>
                              "Type something and press enter..."
                            }
                            isMulti
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            placeholder="Type something and press enter..."
                            name="primary_problem"
                            styles={this.customStyles}
                            value={this.state.passions}
                            onChange={(e) => {
                              if (e) {
                                this.setState({ passions: e });
                              } else if (!e) {
                                this.setState({ passions: "" });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="fwm-right-column w-md-50">
                      <div class="fwm-content-box">
                        <h6>Detailed Info</h6>
                        {this.renderTextarea("bio", "Bio")}
                        {this.renderTextarea(
                          "hope",
                          "What are you hoping to gain from this community?"
                        )}
                        {this.renderTextarea(
                          "contribute",
                          "What can you contribute to this community?"
                        )}
                        <h6>Social Media</h6>
                        {this.renderInput("instagram", "LinkedIn")}
                        {this.renderInput("facebook", "Facebook")}
                        {this.renderInput("twitter", "Twitter")}
                        <div class="form-group text-end form-button">
                          <SubmitBtn
                            btnClass="btn btn-primary"
                            loading={this.state.loading}
                            label="Update"
                            onClickHandle={this.doSubmit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.updateProfile && (
          <div class="modal-backdrop fade show"></div>
        )}
        <ImageCropModel
          profile={this.props.profile}
          cropperModal={this.state.cropper}
          toggleCrop={this.toggleCrop}
          image={this.state.image}
          loadProfile={this.props.getProfile}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProfileUser: (data, callback) =>
    dispatch(updateProfileUser(data, callback)),
  getProfile: () => dispatch(getProfile()),
});

export default withRouter(connect(null, mapDispatchToProps)(UpdateProfile));
