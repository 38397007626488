import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";
import {
  deleteVideo,
  getEvents,
  getEventsDetail,
  uploadVideo,
} from "../../store/events";
import Loader from "../common/loader";
import { toast } from "react-toastify";
import closeBtn from "../../include/images/close-btn.svg";

class UploadVid extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    type: "upload",
    vid: {},
    browserLabel: "Browse",
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.uploadVid && this.props.uploadVid !== prevProps.uploadVid) {
      this.props.getEventsDetail(this.props.event_id);
    }
  };

  uploadFile = async (e) => {
    this.ref.current.continuousStart();
    this.setState({
      loading: true,
      browserLabel: "Uploading...",
    });
    this.setState({ imageName: e.target.files[0].name });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("video", file);

    this.props.uploadVideo(this.props.event_id, data, (res) => {
      this.setState({ vid: res.data.data });
      this.props.getEventsDetail(this.props.event_id);
      this.setState({
        loading: false,
        browserLabel: "Browse",
      });
      this.ref.current.complete();
    });
  };

  deleteVid = (id) => {
    this.props.deleteVideo(this.props.event_id, id, (res) => {
      if (res && res.status === 200) {
        this.props.getEventsDetail(this.props.event_id);
        toast(<AlertSuccess message={"Deleted Successfully"} />);
      } else {
        return toast(<AlertError message={res.data.message} />);
      }
    });
  };

  render() {
    const detail = this.props.detail;
    return (
      <>
        <div
          class={
            this.props.uploadVid
              ? "modal right fade side-flyer-container show d-block"
              : "modal right fade side-flyer-container d-none"
          }
          id="uploadVideo"
          tabindex="-1"
          aria-labelledby="uploadVideoLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <Loader laoderRef={this.ref} />
            <div class="modal-content">
              <div class="dn-head d-flex align-items-center justify-content-between">
                <h5 class="modal-title" id="exampleModalLabel">
                  Upload video
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.toggleVideo}
                ></button>
              </div>
              <div class="dn-body ">
                <div class="form-group">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="file-type"
                      id="file-type1"
                      checked={this.state.type === "upload"}
                      onChange={() => this.setState({ type: "upload" })}
                    />
                    <label class="form-check-label" for="file-type1">
                      Upload a Video
                    </label>
                  </div>
                  {/* <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="file-type"
                      id="file-type2"
                      checked
                    />
                    <label class="form-check-label" for="file-type2">
                      Youtube URL
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="file-type"
                      id="file-type3"
                      checked
                    />
                    <label class="form-check-label" for="file-type3">
                      Vimeo URL
                    </label>
                  </div> */}
                </div>
                <div class="form-group">
                  <label for="name" class="form-label">
                    Upload a Video
                  </label>
                  <div class="input-form-group position-relative">
                    <input type="name" class="form-control" />
                    <div class="custom-file-uploader">
                      <input
                        type="file"
                        name="file"
                        accept="video/*"
                        onChange={this.uploadFile}
                      />
                      {this.state.browserLabel}
                    </div>
                  </div>
                  <div>
                    (Video file cannot be more than 200MB. Only .mp4 and .mov
                    formats are allowed)
                  </div>
                </div>
                <div class="form-group">
                  <label>Preview</label>
                  <ul class="upload-img-list d-flex flex-column flex-wrap">
                    {detail &&
                      detail.videos &&
                      detail.videos.length > 0 &&
                      detail.videos.map((vid) => (
                        <li style={{ width: "100%" }}>
                          <div class="ui-box">
                            <div class="ui-img">
                              <video
                                style={{ width: "100%" }}
                                controls
                                src={
                                  vid.url && process.env.REACT_APP_CDN + vid.url
                                }
                              />
                            </div>
                            <div
                              class="close-img"
                              onClick={() => this.deleteVid(vid._id)}
                            >
                              <img src={closeBtn} alt="" />
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div class="dn-footer">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  onClick={this.props.toggleVideo}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.uploadVid && (
          <div className="modal-backdrop fade show"></div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: getEvents(state).detail,
});
const mapDispatchToProps = (dispatch) => ({
  uploadVideo: (id, data, callback) =>
    dispatch(uploadVideo(id, data, callback)),
  getEventsDetail: (id, callback) => dispatch(getEventsDetail(id, callback)),
  deleteVideo: (eventid, id, callback) =>
    dispatch(deleteVideo(eventid, id, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadVid)
);
