import React from "react";
import infoIcon from "../../../include/images/Info-icon.svg";

const AlertError = (props) => {
  return (
    <>
      <span class="alert-icon">
        <i>
          <img src={infoIcon} alt="" />
        </i>
      </span>

      <p>{props.message}</p>
    </>
  );
};

export default AlertError;
