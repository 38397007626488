import React, { Component } from "react";
import member from "../../include/images/member-bg.png";
import lineCircle from "../../include/images/line-circle.png";
import successCircle from "../../include/images/success-circle.svg";

class Success extends Component {
  render() {
    return (
      <div class="member-container d-lg-flex">
        <div class="member-left">
          <div class="member-bg">
            <img src={member} alt="" />
            <div class="circle-img">
              <img src={lineCircle} alt="" />
            </div>
          </div>
          <div class="member-content-box">
            <h6>Welcome to</h6>
            <h3>
              THE GOLDN <span>link</span>
            </h3>
            <p>
              We’re on a mission to empower <span>Black</span> and{" "}
              <span>Brown</span> women around the world and help them build the
              necessary connections to change their circumstances.
            </p>
          </div>
        </div>
        <div class="member-right">
          <div class="member-form-container d-flex align-items-center justify-content-center">
            <div class="member-form-box success-box">
              <div class="success-img">
                <img src={successCircle} alt="" />
              </div>
              <h1>Password Changed Successfully</h1>
              <div class="form-group">
                <input
                  type="submit"
                  name="Login"
                  onClick={() => this.props.history.push("/sign-in")}
                  class="btn btn-primary btn-block "
                  value="Go back to Login"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Success;
