import React, { Component, createRef } from "react";
import gsap from "gsap";

class SeeYourPower extends Component {
  mainText1 = createRef(null);
  subText1 = createRef(null);
  componentDidMount = () => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.mainText1,
          start: "top bottom",
          scrub: true,
        },
      })
      .to(this.mainText1, {
        y: 25,
      });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: this.subText1,
          start: "top bottom",
          scrub: true,
        },
      })
      .to(this.subText1, {
        y: -20,
      });
  };
  render() {
    return (
      <div class="text-cta-container">
        <div class="container">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="main-text" ref={(el) => (this.mainText1 = el)}>
                See Your Power{" "}
                <span class="sub-text" ref={(el) => (this.subText1 = el)}>
                  Empower Others.
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SeeYourPower;
