import { combineReducers } from "redux";

import usersReducer from "./users";
import miscReducer from "./misc";
import eventsReducer from "./events";
import forumReducer from "./forum";
import commentReducer from "./comment"
import donateReducer from "./donate"


export default combineReducers({
  users: usersReducer,
  misc: miscReducer,
  events: eventsReducer,
  forum: forumReducer,
  comment: commentReducer,
  donate: donateReducer,
});
