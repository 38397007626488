import React from "react";
import checkIcon from "../../../include/images/check-icon.svg";

const AlertSuccess = (props) => {
  return (
    <>
      <span class="alert-icon">
        <i>
          <img src={checkIcon} alt="" />
        </i>
      </span>
      <p>{props.message}</p>
    </>
  );
};

export default AlertSuccess;
