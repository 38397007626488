import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ComingSoon from "./pages/comingSoon";
import Home from "./Component/home/home"
import { Provider } from "react-redux";
import Signin from "./Component/signIn/signin";
import Signup from "./Component/signUp/signup";
import configureStore from "./store/configureStore";
import AlertClose from "./Component/common/alert/alertClose"
import ForgotPassword from "./Component/signIn/forgotPassword";
import Dashboard from "./Component/dashboard/dashboard";
import CommunityMember from "./Component/community/communityMember";
import VerifyOtp from "./Component/signUp/verifyOtp";
import Success from "./Component/common/success";
import ContactUs from "./Component/common/contactUs";
import Events from "./Component/events/events";
import EventDetail from "./Component/events/eventDetail";
import Donate from "./Component/donation/donate";
import Forum from "./Component/forum/forum";
import ChangePassword from "./Component/signIn/changePassword";
import Rolodex from "./Component/rolodex/rolodex";
import ForumDetail from "./Component/forum/forumDetail";

import "./include/css/bootstrap.min.css"
import "./include/css/style.css"
import "./include/css/dev.css"
import "./include/css/hero.css"

function App() {
  const store = configureStore()
  return <Provider store={store}>
    <ToastContainer
      position="top-right"
      hideProgressBar
      autoClose={5000}
      toastClassName="alert alert-success alert-white"
      closeButton={<AlertClose />}
    />
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/coming-soon" component={ComingSoon} />
        <Route exact path="/sign-in" component={Signin} />
        <Route exact path="/sign-up" component={Signup} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/community-member" component={CommunityMember} />
        <Route exact path="/verify-email" component={VerifyOtp} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/event-detail/:id" component={EventDetail} />
        <Route exact path="/donate" component={Donate} />
        <Route exact path="/forum" component={Forum} />
        <Route exact path="/forum-detail/:id" component={ForumDetail} />
        <Route exact path="/reset-password" component={ChangePassword} />
        <Route exact path="/rolodex" component={Rolodex} />
      </Switch>
    </Router>
  </Provider>;
}

export default App;
