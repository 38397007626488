import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";
import gsap from "gsap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { deleteEvent, searchEvents } from "../../store/events";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";
import moment from "moment";
import dotIcon from "../../include/images/dots-icon.svg";

class EventCard extends Component {
  animateRef4 = createRef(null);
  state = {
    dropdown: false,
  };

  toggleDropdown = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };

  componentDidMount = () => {
    gsap.to(this.animateRef4, {
      scrollTrigger: {
        trigger: this.animateRef4,
        once: true,
        start: "top 90%",
        toggleClass: "animated",
      },
    });
    document.addEventListener("click", this.handleClickOutside, true);
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        dropdown: false,
      });
    }
  };

  render() {
    const { data } = this.props;
    return (
      <li
        class="post-item animate-child"
        ref={(el) => (this.animateRef4 = el)}
        key={this.props.key}
      >
        <div class="post-box d-lg-flex flex-lg-wrap align-items-lg-center">
          <div class="post-img-box">
            <div
              style={{ cursor: "pointer" }}
              class="post-img"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push({
                  pathname: "/event-detail/" + data.slug,
                  state: { key: data.isCommunityEvent },
                });
              }}
            >
              <img
                src={process.env.REACT_APP_CDN + data.coverImage.medium}
                alt=""
              />
            </div>
            <div
              class="box-A"
              style={{
                background: data.isCommunityEvent
                  ? "rgb(0 0 0 / 20%)"
                  : "#ebc7b0",
              }}
            ></div>
          </div>
          <div class="post-text-box">
            <h6
              style={{
                color: data.isCommunityEvent ? "rgb(0 0 0 / 40%)" : "#ebc7b0",
              }}
            >
              {data.isCommunityEvent ? "Community Event" : "Goldnlink Event"}
            </h6>
            <h4>
              <span>{moment(data.startDate).format("DD MMMM YYYY")}</span> -
              {` ${moment(data.startDate).format("hh:mm A")} to ${moment(
                data.endDate
              ).format("hh:mm A")}`}
            </h4>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.history.push({
                  pathname: "/event-detail/" + data.slug,
                  state: { key: data.isCommunityEvent },
                })
              }
            >
              {data.title}
            </h3>
            <p>{data.description}</p>
            {this.props.myEvents &&
              this.props.myEvents.length > 0 &&
              this.props.myEvents.find((id) => id._id === data._id) && (
                <div
                  class="dropdown post-dropdown"
                  onClick={() => {
                    this.setState({ dropdown: !this.state.dropdown });
                    this.toggleDropdown();
                  }}
                >
                  <div
                    class={
                      this.state.dropdown
                        ? "dropdown-toggle show"
                        : "dropdown-toggle"
                    }
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="inside"
                    aria-expanded={this.state.dropdown ? "false" : "true"}
                    onClick={() => {
                      this.setState({ dropdown: !this.state.dropdown });
                      this.toggleDropdown();
                    }}
                  >
                    <img src={dotIcon} alt="" />
                  </div>
                  <ul
                    class={
                      this.state.dropdown
                        ? "dropdown-menu dropdown-menu-lg-end show"
                        : "dropdown-menu dropdown-menu-lg-end"
                    }
                  >
                    {!(moment(data.endDate) < moment()) && (
                      <li>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          onClick={(e) => {
                            this.props.toggleAddEvent(e, true, this.props.data);
                            this.setState({ dropdown: false });
                          }}
                        >
                          Edit event
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        class="dropdown-item"
                        href="#!"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadImages"
                        onClick={() => this.props.toggleImage(data._id)}
                      >
                        Upload Images
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#!"
                        data-bs-toggle="modal"
                        data-bs-target="#uploadVideo"
                        onClick={() => this.props.toggleVideo(data._id)}
                      >
                        Upload Videos
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item delete"
                        href="javascript:void(0)"
                        onClick={() => {
                          this.props.toggleDelete(data._id);
                          this.setState({ dropdown: false });
                        }}
                      >
                        Delete
                      </a>
                    </li>
                  </ul>
                </div>
              )}
          </div>
        </div>
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteEvent: (id, callback) => dispatch(deleteEvent(id, callback)),
  searchEvents: (data, callback) => dispatch(searchEvents(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(EventCard));
