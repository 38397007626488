import React, { Component } from "react";
import eyeOff from "../../../include/images/eye-off.svg";
import eye from "../../../include/images/eye.svg";

class Password extends Component {
  state = {
    isPasswordShown: false,
    formclass: "form-group custom-input",
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input";
    this.setState({ formclass: classname });
  };

  toggleShowPassword = (e) => {
    this.setState({ isPasswordShown: !this.state.isPasswordShown });
    this.input.current.type = this.state.isPasswordShown ? "password" : "text";
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input";
      this.setState({ formclass: classname });
    }
  }

  render() {
    const { name, label, error, ...rest } = this.props;

    return (
      <div
        className={this.state.formclass}
        style={{ marginBottom: this.props.page ? "0" : "30px" }}
      >
        <label htmlFor={name} class="form-label">
          {label}
        </label>
        <div className="input-group">
          <input
            name={name}
            id={name}
            className={error ? "form-control error" : "form-control"}
            type="password"
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            ref={this.input}
            {...rest}
          />
          {this.state.isPasswordShown ? (
            <div class="eye-icon-box showpwd" onClick={this.toggleShowPassword}>
              <img src={eyeOff} alt="" />
            </div>
          ) : (
            <div class="eye-icon-box showpwd" onClick={this.toggleShowPassword}>
              <img src={eye} alt="" />
            </div>
          )}
          {/* <div onClick={this.toggleShowPassword}>
            {this.state.isPasswordShown ? (
              <i class="fa fa-eye showpwd"> </i>
            ) : (
              <i className="fa fa-eye-slash showpwd"></i>
            )}
          </div> */}
        </div>
        {error && <label className="error">{error}</label>}
      </div>
    );
  }
}

export default Password;
