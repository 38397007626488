import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getEvents, getEventsDetail } from "../../store/events";
import Footer from "../common/footer";
import Header from "../common/header";
import Loader from "../common/loader";
import Sidebar from "../common/sidebar";
import SocialMedia from "../common/socialMedia";
import gsap from "gsap";
import moment from "moment";
import PhotoModal from "./photoModal";
import Vidmodal from "./vidmodal";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

class EventDetail extends Component {
  ref = createRef(null);
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  animateRef3 = createRef(null);
  animateRef4 = createRef(null);
  animateRef5 = createRef(null);
  animateRef6 = createRef(null);
  nextRef = createRef(null);
  prevRef = createRef(null);

  state = {
    menu: false,
    photoModal: false,
    vidModal: false,
    photo: [],
    video: [],
  };

  togglePhoto = (photo) => {
    this.setState({ photoModal: !this.state.photoModal, photo }, () => {
      if (this.state.photoModal) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  toggleVideo = (video) => {
    this.setState({ vidModal: !this.state.vidModal, video }, () => {
      if (this.state.vidModal) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.props.getEventsDetail(this.props.match.params.id);
    }

    const animateRef = [
      this.animateRef1,
      this.animateRef2,
      this.animateRef5,
      this.animateRef6,
    ];
    animateRef.forEach((cb) => {
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      });
    });
    this.animateRef3 &&
      this.animateRef3.current &&
      gsap.to(this.animateRef3, {
        scrollTrigger: {
          trigger: this.animateRef3,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      });

    this.animateRef4 &&
      this.animateRef4.current &&
      gsap.to(this.animateRef4, {
        scrollTrigger: {
          trigger: this.animateRef4,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  render() {
    const { detail } = this.props;

    return (
      <>
        <Helmet>
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_BASEURL}event-detail/${detail.slug}`}
          />
          <meta property="og:type" content="event" />
          <meta property="og:title" content={detail.title} />
          <meta property="og:description" content={detail.description} />
          <meta
            property="og:image"
            content={
              detail &&
              detail.coverImage &&
              process.env.REACT_APP_CDN + detail.coverImage.original
            }
          />
        </Helmet>
        <Loader laoderRef={this.ref} />
        <Header
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true}
        />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="page-single-bg">
          <img
            src={
              detail &&
              detail.coverImage &&
              process.env.REACT_APP_CDN + detail.coverImage.large
            }
            alt=""
          />
        </div>
        <div class="single-image-container">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="single-image-row animate-child"
                  ref={(el) => (this.animateRef1 = el)}
                >
                  <div class="swiper-wrapper">
                    <div className="swiper-slide si-item">
                      <img
                        src={
                          detail &&
                          detail.coverImage &&
                          process.env.REACT_APP_CDN + detail.coverImage.original
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class={
            this.props.location &&
            this.props.location.state &&
            !this.props.location.state.key
              ? "single-event-container"
              : "single-event-container community-event-container has-grey-line"
          }
        >
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="single-event-box d-md-flex flex-md-wrap">
                  <div class="se-sidebar">
                    <div
                      class="event-date-box text-center animate-child"
                      ref={(el) => (this.animateRef2 = el)}
                    >
                      <b>{moment(detail.startDate).format("DD")}</b>
                      <p>{moment(detail.startDate).format("MMMM")}</p>
                      <div class="add-line"></div>
                    </div>
                    {detail && detail.images && detail.images.length > 0 && (
                      <a
                        href="javascript:void(0)"
                        class={
                          this.props.location &&
                          this.props.location.state &&
                          !this.props.location.state.key
                            ? "btn btn-default btn-block animate-child"
                            : "btn btn-grey btn-block animate-child"
                        }
                        ref={(el) => (this.animateRef3 = el)}
                        data-bs-toggle="modal"
                        data-bs-target="#photosPopup"
                        onClick={() => this.togglePhoto(detail.images)}
                      >
                        Photos
                      </a>
                    )}
                    {detail && detail.videos && detail.videos.length > 0 && (
                      <a
                        href="javascript:void(0)"
                        class={
                          this.props.location &&
                          this.props.location.state &&
                          !this.props.location.state.key
                            ? "btn btn-default btn-block animate-child"
                            : "btn btn-grey btn-block animate-child"
                        }
                        ref={(el) => (this.animateRef4 = el)}
                        data-bs-toggle="modal"
                        data-bs-target="#videosPopup"
                        onClick={() => this.toggleVideo(detail.videos)}
                      >
                        Videos
                      </a>
                    )}
                    <ul
                      class="social-link animate-child"
                      ref={(el) => (this.animateRef5 = el)}
                    >
                      <li>
                        <FacebookShareButton
                          url={`${process.env.REACT_APP_BASEURL}event-detail/${detail.slug}`}
                          quote={detail.title}
                          hashtag="#events"
                        >
                          <a href="#!">
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </FacebookShareButton>
                      </li>

                      <li>
                        <TwitterShareButton
                          url={`${process.env.REACT_APP_BASEURL}event-detail/${detail.slug}`}
                          title={detail.title}
                          hashtags={["events"]}
                        >
                          <a href="#!">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          url={`${process.env.REACT_APP_BASEURL}event-detail/${detail.slug}`}
                          title={detail.title}
                          summary={detail.description}
                          source={`${process.env.REACT_APP_BASEURL}`}
                        >
                          <a href="#!">
                            <i class="fab fa-linkedin-in"></i>
                          </a>
                        </LinkedinShareButton>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="se-main-content animate-child"
                    ref={(el) => (this.animateRef6 = el)}
                  >
                    <h1>
                      {" "}
                      {detail.isCommunityEvent
                        ? "Community Event"
                        : "Goldnlink Event"}
                    </h1>
                    <h3>{detail.title}</h3>
                    <h4>
                      <span>
                        {moment(detail.startDate).format("DD MMMM YYYY")}
                      </span>{" "}
                      -{" "}
                      {` ${moment(detail.startDate).format(
                        "hh:mm A"
                      )} - ${moment(detail.endDate).format("hh:mm A")}`}
                    </h4>
                    {detail.description &&
                      detail.description.split("\n").map((el) => <p>{el}</p>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SocialMedia />
        <Footer history={this.props.history} />
        <PhotoModal
          togglePhoto={this.togglePhoto}
          photoModal={this.state.photoModal}
          photo={this.state.photo}
        />
        <Vidmodal
          toggleVideo={this.toggleVideo}
          vidModal={this.state.vidModal}
          video={this.state.video}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: getEvents(state).detail,
});

const mapDispatchToProps = (dispatch) => ({
  getEventsDetail: (id, callback) => dispatch(getEventsDetail(id, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventDetail)
);
