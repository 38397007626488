import React, { Component, createRef } from "react";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import Hero from "./hero";
import VideoCont from "./videoCont";
import WhatWeDo from "./whatWeDo";
import SocialMedia from "../common/socialMedia";
import Footer from "../common/footer";
import ContactModal from "../common/contactModal";
import { getAchievement, getMediaUrl, getMisc } from "../../store/misc";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../common/loader";

class Home extends Component {
  nextRef = createRef(null);
  prevRef = createRef(null);
  ref = createRef(null);
  state = {
    menu: false,
    contactUs: false,
  };

  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    document.body.classList.add("home");

    this.props.getAchievement();
  };
  componentWillUnmount = () => {
    document.body.classList.remove("home");
  };

  toggleModal = () => {
    this.setState({ contactUs: !this.state.contactUs }, () => {
      if (this.state.contactUs) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else if (!this.state.contactUs) {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };
  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  render() {
    const media = this.props.media;
    const achievement = this.props.achievement;
    return (
      <>
        <Loader laoderRef={this.ref} />
        <Header menu={this.state.menu} toggleMenu={this.toggleMenu} />
        <Hero />
        <Sidebar
          toggleMenu={this.toggleMenu}
          history={this.props.history}
          media={this.props.media}
        />
        <VideoCont />
        <WhatWeDo
          nextRef={this.nextRef}
          prevRef={this.prevRef}
          setnextRef={(el) => (this.nextRef = el)}
          setprevRef={(el) => (this.prevRef = el)}
          achievement={achievement}
          history={this.props.history}
        />
        <SocialMedia media={this.props.media} />
        <Footer history={this.props.history} />
        <div class="overlay" onClick={this.toggleMenu} id="overlay"></div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  achievement: getMisc(state).achievement,
  loading: getMisc(state).loading,
});

const mapDispatchToProps = (dispatch) => ({
  getAchievement: () => dispatch(getAchievement()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
