import React, { Component, createRef } from "react";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import { gsap } from "gsap";
import SocialMedia from "../common/socialMedia";
import Footer from "../common/footer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  addToRodolex,
  getUser,
  searchUser,
  getProfile,
} from "../../store/users";
import LinesEllipsis from "react-lines-ellipsis";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import AlertSuccess from "../common/alert/alertSuccess";
import CommunityPopup from "./communityPopup";
import { getToken } from "../../utils/localStorageServices";
import Loader from "../common/loader";
import Search from "../../include/images/search.svg";
import bookwork from "../../include/images/bookwork-1.svg";
import bookmark from "../../include/images/bookmark.svg";
import memberIcon from "../../include/images/member-icon.jpg";
import folderIcon from "../../include/images/folder-icon.svg";

class CommunityMember extends Component {
  ref = createRef(null);
  animateRef1 = React.createRef(null);

  state = {
    menu: false,
    contactUs: false,
    keyword: "",
    page: 1,
    loading: false,
    detailPop: false,
    id: "",
    pageLoader: true,
    rolodex: false,
  };

  componentDidMount = () => {
    this.setState({ pageLoader: true });
    if (getToken("refresh")) {
      this.props.getProfile();
    }
    this.props.searchUser({}, () => this.setState({ pageLoader: false }));
    window.scrollTo({ top: "0", behavior: "smooth" });
    // this.animateRef1 &&
    // gsap.to(this.animateRef1, {
    //   scrollTrigger: {
    //     trigger: this.animateRef1,
    //     once: true,
    //     start: "top 90%",
    //     toggleClass: "animated",
    //   },
    // });
  };

  handleRobolex = (e, id, data) => {
    this.setState({ loading: true, rolodex: data });
    if (e) e.preventDefault();
    this.props.addToRodolex(id, data, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res && res.status === 200) {
      this.props.getProfile();
      //this.props.searchUser({ page: this.state.page });
      if (this.state.rolodex) {
        toast(<AlertSuccess message={"Added to Rolodex"} />);
      } else {
        toast(<AlertSuccess message={"Removed from Rolodex"} />);
      }
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  handlePageClick = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.setState({ page: e.selected + 1 }, () =>
      this.props.searchUser({ page: this.state.page })
    );
  };

  toggleModal = (id) => {
    this.setState({ detailPop: !this.state.detailPop, id }, () => {
      if (this.state.detailPop) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else if (!this.state.detailPop) {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  render() {
    const profile = this.props.profile;
    const loading = this.props.loading;
    return (
      <>
        <Loader laoderRef={this.ref} />
        <Header
          dashboard={true}
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true}
        />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="content-container community-container">
          <div class="container">
            {this.props.users && this.props.users.length > 0 ? (
              <div class="row">
                <div class="col-lg-12">
                  <div class="community-upper d-flex justify-content-between align-items-center">
                    <div class="heading">
                      <h3 class="mb-0">Community</h3>
                    </div>
                    <div class="search-box">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">
                          <img src={Search} alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search member"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={this.state.keyword}
                          onChange={(e) =>
                            this.setState({ keyword: e.target.value }, () =>
                              this.props.searchUser({
                                keyword: this.state.keyword,
                              })
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div class="community-lower">
                    <ul
                      class="community-member-list d-flex flex-wrap"
                      // ref={(el) => (this.animateRef1 = el)}
                    >
                      {this.props.users &&
                        this.props.users.map((e, i) => (
                          <li class="cm-item" key={i}>
                            <div class="cm-box">
                              <div class="cm-head">
                                <p>{e.firstName + " " + e.lastName}</p>
                                <div class="bookmark-icon">
                                  <a
                                    href="javascript:void(0)"
                                    title={
                                      profile &&
                                      profile.robolex &&
                                      profile.robolex.find((id) => e._id === id)
                                        ? "Remove from Rolodex"
                                        : "Add to Rolodex"
                                    }
                                    class="cm-tooltip"
                                    onClick={(event) => {
                                      if (getToken("refresh")) {
                                        this.handleRobolex(
                                          event,
                                          e._id,
                                          profile &&
                                            profile.robolex &&
                                            profile.robolex.find(
                                              (id) => e._id === id
                                            )
                                            ? false
                                            : true
                                        );
                                      } else {
                                        return toast(
                                          <AlertError
                                            message={
                                              "Please login to add user to Rolodex"
                                            }
                                          />
                                        );
                                      }
                                    }}
                                  >
                                    <img
                                      src={
                                        profile &&
                                        profile.robolex &&
                                        profile.robolex.find(
                                          (id) => e._id === id
                                        )
                                          ? bookwork
                                          : bookmark
                                      }
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                              <div class="cm-body">
                                <div
                                  class="member-img"
                                  onClick={() => {
                                    if (getToken("refresh")) {
                                      this.toggleModal(e._id);
                                    }
                                  }}
                                >
                                  <img
                                    style={{
                                      cursor: getToken("refresh")
                                        ? "pointer"
                                        : "default",
                                    }}
                                    src={
                                      e && e.profilePicture
                                        ? process.env.REACT_APP_CDN +
                                          e.profilePicture.small
                                        : memberIcon
                                    }
                                    alt=""
                                  />
                                </div>
                                <p class="cm-space">{e.location}</p>
                                <h5
                                  style={{
                                    cursor: getToken("refresh")
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={() => {
                                    if (getToken("refresh")) {
                                      this.toggleModal(e._id);
                                    }
                                  }}
                                >
                                  {e.firstName + " " + e.lastName}
                                </h5>
                                <p class="cm-profile">{e.profession}</p>
                                <p class="cm-content">
                                  <LinesEllipsis
                                    text={e.bio}
                                    maxLine="4"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                  />
                                </p>
                              </div>
                              <div class="cm-footer">
                                <ul class="social-nav d-flex flex-wrap justify-content-center">
                                  {e.socialMediaLinks &&
                                    e.socialMediaLinks.facebook && (
                                      <li>
                                        <a
                                          href={e.socialMediaLinks.facebook}
                                          target="_blank"
                                        >
                                          <i class="fab fa-facebook-f"></i>
                                        </a>
                                      </li>
                                    )}
                                  {e.socialMediaLinks &&
                                    e.socialMediaLinks.twitter && (
                                      <li>
                                        <a
                                          href={e.socialMediaLinks.twitter}
                                          target="_blank"
                                        >
                                          <i class="fab fa-twitter"></i>
                                        </a>
                                      </li>
                                    )}
                                  {e.socialMediaLinks &&
                                    e.socialMediaLinks.instagram && (
                                      <li>
                                        <a
                                          href={e.socialMediaLinks.instagram}
                                          target="_blank"
                                        >
                                          <i class="fab fa-linkedin-in"></i>
                                        </a>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <ReactPaginate
                      forcePage={this.state.page - 1}
                      pageClassName={""}
                      pageLinkClassName={""}
                      previousClassName={"cm-prev-btn"}
                      nextClassName={"cm-next-btn"}
                      previousLinkClassName={"btn btn-gray"}
                      nextLinkClassName={"btn btn-outline"}
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.pagination.total_page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={
                        "cm-pagination d-flex justify-content-between"
                      }
                      activeLinkClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="community-upper d-flex justify-content-between align-items-center">
                      <div class="heading mb-0">
                        <h3>Community</h3>
                      </div>
                      <div class="search-box">
                        <div class="input-group flex-nowrap">
                          <span class="input-group-text" id="addon-wrapping">
                            <img src={Search} alt="" />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Example_Name"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            value={this.state.keyword}
                            onChange={(e) =>
                              this.setState({ keyword: e.target.value }, () =>
                                this.props.searchUser({
                                  keyword: this.state.keyword,
                                })
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!loading && (
                  <div class="row">
                    <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                      <div class="cm-no-found ">
                        <div class="no-found-icon">
                          <img src={folderIcon} alt="" />
                        </div>
                        <h3>No Information found!</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Aenean fermentum, nec dis elementum id id.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <SocialMedia />
        <Footer
          contactUs={this.state.contactUs}
          toggleModal={this.toggleModal}
          history={this.props.history}
        />
        <div class="overlay" onClick={this.toggleMenu} id="overlay"></div>
        <CommunityPopup
          detailPop={this.state.detailPop}
          toggleModal={this.toggleModal}
          id={this.state.id}
          profile={this.props.profile}
          handleRobolex={this.handleRobolex}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  users: getUser(state).users,
  pagination: getUser(state).pagination,
  loading: getUser(state).loading,
  profile: getUser(state).profile,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  searchUser: (data, callback) => dispatch(searchUser(data, callback)),
  addToRodolex: (id, data, callback) =>
    dispatch(addToRodolex(id, data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommunityMember)
);
