import React from "react";
import { Link } from "react-router-dom";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { checkEmail, signUp } from "../../store/users";
import {
  confirmPasswordMatch,
  confirmPasswordRequired,
  passwordInvalid,
  passwordRequired,
  passwordInvalidMax,
} from "../common/misc";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import { setToken } from "../../utils/localStorageServices";
import lineCircle from "../../include/images/line-circle.png";
import memberBg from "../../include/images/member-bg.png";

class Signup extends Form {
  state = {
    loading: false,
    data: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    errors: {},
  };
  schema = {
    firstName: Joi.string()
      .required()
      .label("First Name")
      .error(() => {
        return { message: "First Name is required" };
      }),

    lastName: Joi.string()
      .required()
      .label("Last Name")
      .error(() => {
        return { message: "Last Name is required" };
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required";
              break;
            case "string.email":
              err.message = "Email is invalid";
              break;
            case "string.max":
              err.message = "Email is invalid";
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "password"
      )
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.regex.name":
              err.message = passwordInvalid;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
          }
        });
        return errors;
      }),
    confirmPassword: Joi.string()
      .required()
      .label("Confirm Password")
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: {
            allowOnly: "!!" + confirmPasswordMatch,
            empty: "!!" + confirmPasswordRequired,
          },
        },
      }),
  };
  componentDidMount = () => {
    document.body.classList.add("p-0");
  };
  componentWillUnmount = () => {
    document.body.classList.remove("p-0");
  };
  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const formData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    };
    this.props.signUp(formData, this.callback);
  };
  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      localStorage.setItem("profile", JSON.stringify(res.data.data));
      localStorage.setItem("otp", JSON.stringify(res.data.data.otp));
      const { refreshToken, jwt } = res.data.data;
      setToken(refreshToken, jwt);
      this.props.history.push("/verify-email");
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    return (
      <div class="member-container d-lg-flex">
        <div class="member-left">
          <div class="member-bg">
            <img src={memberBg} alt="" />
            <div class="circle-img">
              <img src={lineCircle} alt="" />
            </div>
          </div>
          <div class="member-content-box">
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              Welcome to
            </h6>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              THE GOLDN <span>link</span>
            </h3>
            <p>
              We’re on a mission to empower <span>Black</span> and{" "}
              <span>Brown</span> women around the world and help them build the
              necessary connections to change their circumstances.
            </p>
          </div>
        </div>
        <div class="member-right">
          <div class="member-form-container d-flex align-items-center justify-content-center">
            <div class="member-form-box">
              <h1>Become a Member</h1>
              <form onSubmit={this.handleSubmit}>
                <div class="row">
                  <div class="col-lg-6">
                    {this.renderInput("firstName", "First Name")}
                  </div>
                  <div class="col-lg-6">
                    {this.renderInput("lastName", "Last Name")}
                  </div>
                </div>
                {this.renderInput("email", " Email Address", "email")}

                {this.renderPasswordInput("password", "Password")}

                {this.renderPasswordInput(
                  "confirmPassword",
                  "Confirm Password"
                )}

                <div class="form-group">
                  <input
                    type="submit"
                    name="Login"
                    class="btn btn-primary  btn-block"
                    value="Sign up"
                  />
                </div>
              </form>
              <div class="already-member text-center">
                <p>
                  Already a member? <Link to="/sign-in">Login</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  checkEmail: (email, callback) => dispatch(checkEmail(email, callback)),
  signUp: (email, callback) => dispatch(signUp(email, callback)),
});
export default withRouter(connect(null, mapDispatchToProps)(Signup));
