import React, { Component, createRef } from "react";
import gsap from "gsap";
import { withRouter } from "react-router-dom";
import moment from "moment";
import dotIcon from "../../include/images/dots-icon.svg";
import ReactDOM from "react-dom";

class ForumCard extends Component {
  state = {
    dropdown: false,
  };

  toggleDropdown = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };
  animateRef = createRef(null);
  componentDidMount = () => {
    gsap.to(this.animateRef, {
      scrollTrigger: {
        trigger: this.animateRef,
        once: true,
        start: "top 90%",
        toggleClass: "animated",
      },
    });
    document.addEventListener("click", this.handleClickOutside, true);
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        dropdown: false,
      });
    }
  };

  render() {
    const data = { ...this.props.data };
    return (
      <li
        class="card-item animate-child"
        ref={(el) => (this.animateRef = el)}
        key={this.props.i}
      >
        <a
          href="javascript:void(0)"
          class="card-box d-flex flex-column"
          style={{
            borderBottom:
              data.hostType === "admin"
                ? "20px solid #ebc7b0"
                : "20px solid #666",
            cursor: "default",
          }}
        >
          <div class="card-img-box overflow-hidden">
            <img
              style={{ cursor: "pointer" }}
              class="card-img-top"
              onClick={() =>
                this.props.history.push({
                  pathname: "/forum-detail/" + data.slug,
                  state: { key: data.hostType },
                })
              }
              src={
                data.gridImage &&
                process.env.REACT_APP_CDN + data.gridImage.original
              }
              alt=""
            />
          </div>
          {this.props.myForumTopic &&
            this.props.myForumTopic.length > 0 &&
            this.props.myForumTopic.find((id) => id._id === data._id) && (
              <div
                class="dropdown post-dropdown"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "50%",
                  top: "10%",
                  right: "4%",
                }}
                onClick={() => {
                  this.setState({ dropdown: !this.state.dropdown });
                  this.toggleDropdown();
                }}
              >
                <div
                  class={
                    this.state.dropdown
                      ? "dropdown-toggle d-flex justify-content-center align-items-center show"
                      : "dropdown-toggle d-flex justify-content-center align-items-center"
                  }
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="inside"
                  aria-expanded={this.state.dropdown ? "false" : "true"}
                  onClick={() => {
                    this.setState({ dropdown: !this.state.dropdown });
                    this.toggleDropdown();
                  }}
                >
                  <img src={dotIcon} alt="" />
                </div>
                <ul
                  style={{ top: "200%", right: "70%" }}
                  class={
                    this.state.dropdown
                      ? "dropdown-menu dropdown-menu-lg-end show"
                      : "dropdown-menu dropdown-menu-lg-end"
                  }
                >
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      onClick={(e) => {
                        this.props.toggleForum(e, true, this.props.data);
                        this.setState({ dropdown: false });
                      }}
                    >
                      Edit
                    </a>
                  </li>

                  <li>
                    <a
                      class="dropdown-item delete"
                      href="javascript:void(0)"
                      onClick={() => {
                        this.props.toggleDelete(data._id);
                        this.setState({ dropdown: false });
                      }}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            )}

          <div class="card-text-box">
            <span class="card-updated-time">
              {moment(data.createdAt).fromNow()}
            </span>
            <p class="card-sub-title">{data.category && data.category.name}</p>
            <h5
              class="card-title"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.history.push({
                  pathname: "/forum-detail/" + data.slug,
                  state: { key: data.hostType },
                })
              }
            >
              {data.name}
            </h5>
            <p class="card-text">{data.shortDescription}</p>
          </div>
          <div class="card-comment-count mt-auto">
            {data.commentCount} comments
          </div>
        </a>
      </li>
    );
  }
}

export default withRouter(ForumCard);
