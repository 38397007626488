import React, { Component, createRef } from "react";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import { gsap } from "gsap";
import UpdateProfile from "./updateProfile";
import ChangePassword from "./changePassword";
import ForumTopic from "./forumTopic";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile, getUser } from "../../store/users";
import ContactModal from "../common/contactModal";
import AddEvent from "../events/addEvent";
import Loader from "../common/loader";
import profile from "../../include/images/profile.svg";
import lock from "../../include/images/lock.svg";
import bookmarkIcon from "../../include/images/bookmark-icon.svg";
import addUser from "../../include/images/add-users.svg";
import calendar from "../../include/images/calendar.svg";
import edit from "../../include/images/edit.svg";

class Dashboard extends Component {
  ref = createRef(null);
  animateRef1 = createRef(null);

  state = {
    menu: false,
    updateProfile: false,
    changePassword: false,
    forumTopic: false,
    contactUs: false,
    addEventFlyer: false,
  };

  toggleModal = () => {
    this.setState({ contactUs: !this.state.contactUs }, () => {
      if (this.state.contactUs) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else if (!this.state.contactUs) {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  toggleAddEvent = (e, edit, editData) => {
    if (e) e.preventDefault();
    this.setState(
      { addEventFlyer: !this.state.addEventFlyer, edit, editData },
      () => {
        if (this.state.addEventFlyer) {
          document.body.classList.add("modal-open");
          document.body.classList.add("modal-style");
        } else if (!this.state.addEventFlyer) {
          document.body.classList.remove("modal-open");
          document.body.classList.remove("modal-style");
        }
      }
    );
  };

  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    const animateRef = [this.animateRef1];
    animateRef.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
    this.props.getProfile();
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  toggleProfile = () => {
    this.setState({ updateProfile: !this.state.updateProfile }, () => {
      if (this.state.updateProfile) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  toggleChangePassword = () => {
    this.setState({ changePassword: !this.state.changePassword }, () => {
      if (this.state.changePassword) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };
  toggleForum = () => {
    this.setState({ forumTopic: !this.state.forumTopic }, () => {
      if (this.state.forumTopic) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  componentWillUnmount = () => {
    this.toggleMenu();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  render() {
    return (
      <>
        <Loader laoderRef={this.ref} />
        <Header
          dashboard={true}
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true}
        />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="dashboard-container">
          <div class="dashboard-bg"></div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="heading">
                  <h3>Dashboard</h3>
                </div>
                <ul
                  class="dashboard-list animate-child d-flex flex-wrap"
                  ref={(el) => (this.animateRef1 = el)}
                >
                  <li class="dl-item" onClick={this.toggleProfile}>
                    <div
                      class="dashboard-box"
                      data-bs-toggle="modal"
                      data-bs-target="#updateProfile"
                    >
                      <div class="box">
                        <img src={profile} alt="" />
                        <p>Update Profile</p>
                      </div>
                    </div>
                  </li>
                  <li class="dl-item" onClick={this.toggleChangePassword}>
                    <div
                      class="dashboard-box"
                      data-bs-toggle="modal"
                      data-bs-target="#changePassword"
                    >
                      <div class="box">
                        <img src={lock} alt="" />
                        <p>Change Password</p>
                      </div>
                    </div>
                  </li>
                  <li
                    class="dl-item"
                    onClick={() => this.props.history.push("/coming-soon")}
                  >
                    <div class="dashboard-box">
                      <div class="box">
                        <img src={addUser} alt="" />
                        <p>Become a Mentee</p>
                      </div>
                    </div>
                  </li>

                  <li
                    class="dl-item"
                    onClick={() => this.props.history.push("/rolodex")}
                  >
                    <div class="dashboard-box">
                      <div class="box">
                        <img src={bookmarkIcon} alt="" />
                        <p>Rolodex</p>
                      </div>
                    </div>
                  </li>
                  <li class="dl-item" onClick={this.toggleAddEvent}>
                    <div class="dashboard-box">
                      <div class="box">
                        <img src={calendar} alt="" />
                        <p>Add an Event</p>
                      </div>
                    </div>
                  </li>
                  <li class="dl-item" onClick={this.toggleForum}>
                    <div
                      class="dashboard-box"
                      data-bs-toggle="modal"
                      data-bs-target="#forumTopic"
                    >
                      <div class="box">
                        <img src={edit} alt="" />
                        <p>Add a Forum Topic</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="overlay" onClick={this.toggleMenu} id="overlay"></div>
        <UpdateProfile
          updateProfile={this.state.updateProfile}
          toggleProfile={this.toggleProfile}
          loading={this.props.loading}
          profile={this.props.profile}
          getProfile={this.props.getProfile}
        />
        <ChangePassword
          changePassword={this.state.changePassword}
          toggleChangePassword={this.toggleChangePassword}
        />
        <ForumTopic
          forumTopic={this.state.forumTopic}
          toggleForum={this.toggleForum}
        />
        <ContactModal
          contactUs={this.state.contactUs}
          toggleModal={this.toggleModal}
        />
        <AddEvent
          addEventFlyer={this.state.addEventFlyer}
          toggleAddEvent={this.toggleAddEvent}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: getUser(state).profile,
  loading: getUser(state).loading,
});
const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
