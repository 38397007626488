import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  deleteImage,
  getEvents,
  getEventsDetail,
  uploadImages,
} from "../../store/events";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import plus from "../../include/images/plus.svg";
import closeBtn from "../../include/images/close-btn.svg";

class UploadImage extends Component {
  ref = createRef(null);
  state = {
    loading: false,
    image: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.uploadImage &&
      this.props.uploadImage !== prevProps.uploadImage
    ) {
      this.props.getEventsDetail(this.props.event_id);
    }
  };
  uploadFile = async (e) => {
    this.ref.current.continuousStart();
    this.setState({
      loading: true,
    });
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = e.target.files[i];
      const data = new FormData();
      data.append("image", file);
      this.props.uploadImages(this.props.event_id, data, (res) => {
        this.setState({ image: res.data.data });
        this.props.getEventsDetail(this.props.event_id);
        this.setState({
          loading: false,
        });
        this.ref.current.complete();
      });
    }
  };

  deleteImg = (id) => {
    this.props.deleteImage(this.props.event_id, id, (res) => {
      if (res && res.status === 200) {
        this.props.getEventsDetail(this.props.event_id);
        toast(<AlertSuccess message={"Deleted Successfully"} />);
      } else {
        return toast(<AlertError message={res.data.message} />);
      }
    });
  };
  render() {
    return (
      <>
        <div
          class={
            this.props.uploadImage
              ? "modal right fade side-flyer-container show d-block"
              : "modal right fade side-flyer-container d-none"
          }
          id="uploadImages"
          tabindex="-1"
          aria-labelledby="uploadImagesLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <Loader laoderRef={this.ref} />
            <div class="modal-content">
              <div class="dn-head d-flex align-items-center justify-content-between">
                <h5 class="modal-title" id="exampleModalLabel">
                  Upload Images
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.toggleImage}
                ></button>
              </div>
              <div class="dn-body ">
                <div class="form-group">
                  <div class="upload-img-wrapper d-flex justify-content-center align-items-center">
                    <input
                      type="file"
                      name="file"
                      style={{ height: "100%", width: "100%" }}
                      accept="image/*"
                      onChange={this.uploadFile}
                      multiple
                    />
                    <div class="add-upload-img">
                      <img src={plus} alt="" />
                      Drag and Drop Images
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Preview</label>
                  <ul class="upload-img-list d-flex flex-wrap">
                    {this.props.detail &&
                      this.props.detail.images &&
                      this.props.detail.images.map((el) => (
                        <li>
                          <div class="ui-box">
                            <div class="ui-img">
                              <img
                                src={
                                  el.urls &&
                                  process.env.REACT_APP_CDN + el.urls.small
                                }
                                alt=""
                              />
                            </div>
                            <div
                              class="close-img"
                              onClick={() => this.deleteImg(el._id)}
                            >
                              <img src={closeBtn} alt="" />
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div class="dn-footer">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  onClick={this.props.toggleImage}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.props.uploadImage && (
          <div className="modal-backdrop fade show"></div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: getEvents(state).detail,
});

const mapDispatchToProps = (dispatch) => ({
  uploadImages: (id, data, callback) =>
    dispatch(uploadImages(id, data, callback)),
  deleteImage: (eventId, id, callback) =>
    dispatch(deleteImage(eventId, id, callback)),
  getEventsDetail: (id) => dispatch(getEventsDetail(id)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadImage)
);
