import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUser, getUserDetail } from "../../store/users";
import close1 from "../../include/images/close-1.svg";
import bookwork from "../../include/images/bookwork-1.svg";
import bookmark from "../../include/images/bookmark.svg";
import memberIcon from "../../include/images/member-icon.jpg";

class CommunityPopup extends Component {
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.detailPop && this.props.detailPop !== prevProps.detailPop) {
      this.props.getUserDetail(this.props.id);
    }
  };
  render() {
    const detail = this.props.detail;
    const profile = this.props.profile;
    return (
      <>
        <div
          class={
            this.props.detailPop
              ? "modal fade community-modal show d-block"
              : "modal fade community-modal d-none"
          }
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
              {!this.props.loading && (
                <div class="modal-body">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={this.props.toggleModal}
                  >
                    <img src={close1} alt="" />
                  </button>

                  <div class="cm-box cm-modal-content">
                    <div class="cm-head">
                      <p>{detail.firstName + " " + detail.lastName}</p>
                      <div class="bookmark-icon">
                        <a
                          href="javascript:void(0)"
                          title={
                            profile &&
                            profile.robolex &&
                            profile.robolex.find((id) => detail._id === id)
                              ? "Remove from Rolodex"
                              : "Add to Rolodex"
                          }
                          class="cm-tooltip"
                          onClick={(event) => {
                            event.preventDefault();
                            this.props.handleRobolex(
                              event,
                              detail._id,
                              profile &&
                                profile.robolex &&
                                profile.robolex.find((id) => detail._id === id)
                                ? false
                                : true
                            );
                          }}
                        >
                          <img
                            src={
                              profile &&
                              profile.robolex &&
                              profile.robolex.find((id) => detail._id === id)
                                ? bookwork
                                : bookmark
                            }
                            alt=""
                          />
                        </a>
                      </div>
                    </div>{" "}
                    <div class="cm-body">
                      <div class="cm-body-content d-flex flex-wrap">
                        <>
                          <div class="cm-member-left">
                            <div class="member-img">
                              <img
                                src={
                                  detail.profilePicture
                                    ? process.env.REACT_APP_CDN +
                                      detail.profilePicture.small
                                    : memberIcon
                                }
                                alt=""
                              />
                            </div>
                            <p class="cm-space">{detail.location}</p>
                            <h5>{detail.firstName + " " + detail.lastName}</h5>
                            <p class="cm-profile">{detail.profession}</p>
                            <ul class="cm-link-list">
                              <li>
                                <a href={`mailto:${detail.email}`}>
                                  {detail.email}
                                </a>
                              </li>
                              <li>
                                <a href={`tel:${detail.mobile}`}>
                                  {detail.mobile}
                                </a>
                              </li>
                            </ul>
                            <div class="cm-footer">
                              <ul class="social-nav d-flex flex-wrap justify-content-center">
                                {detail.socialMediaLinks &&
                                  detail.socialMediaLinks.facebook && (
                                    <li>
                                      <a
                                        href={detail.socialMediaLinks.facebook}
                                        target="_blank"
                                      >
                                        <i class="fab fa-facebook-f"></i>
                                      </a>
                                    </li>
                                  )}
                                {detail.socialMediaLinks &&
                                  detail.socialMediaLinks.twitter && (
                                    <li>
                                      <a
                                        href={detail.socialMediaLinks.twitter}
                                        target="_blank"
                                      >
                                        <i class="fab fa-twitter"></i>
                                      </a>
                                    </li>
                                  )}
                                {detail.socialMediaLinks &&
                                  detail.socialMediaLinks.instagram && (
                                    <li>
                                      <a
                                        href={detail.socialMediaLinks.instagram}
                                        target="_blank"
                                      >
                                        <i class="fab fa-linkedin-in"></i>
                                      </a>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                          <div class="cm-member-right">
                            <div class="member-info">
                              <h5>Passion</h5>
                              <ul class="member-info d-flex flex-wrap">
                                {detail.passions &&
                                  detail.passions.length > 0 &&
                                  detail.passions.map((p) => <li>{p}</li>)}
                              </ul>
                              <h5>Bio</h5>
                              {detail.bio &&
                                detail.bio
                                  .split("\n")
                                  .map((el, i) => <p key={i}>{el}</p>)}
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.detailPop && <div class="modal-backdrop fade show"></div>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: getUser(state).detail,
  loading: getUser(state).loading,
});

const mapDispatchToProps = (dispatch) => ({
  getUserDetail: (id) => dispatch(getUserDetail(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommunityPopup)
);
