import React, { Component, createRef } from "react";
import gsap from "gsap";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import SocialMedia from "../common/socialMedia";
import Footer from "../common/footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  deleteForum,
  getForum,
  getFoumCatergory,
  getMyForumTopic,
  searchForumTopic,
} from "../../store/forum";
import ForumCard from "./forumCard";
import Loader from "../common/loader";
import search from "../../include/images/search.svg";
import DeletePopup from "../common/deletePopup";
import ForumTopic from "../dashboard/forumTopic";
import { getToken } from "../../utils/localStorageServices";
import folderIcon from "../../include/images/folder-icon.svg";

class Forum extends Component {
  ref = createRef(null);
  animateRef0 = createRef(null);
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  animateRef12 = createRef(null);
  nextRef = createRef(null);
  prevRef = createRef(null);
  state = {
    menu: false,
    category: "All",
    page: 1,
    keyword: "",
    delete: false,
    delete_id: "",
    forumTopic: false,
    edit: false,
    editData: null,
  };

  toggleDelete = (id) => {
    this.setState({ delete: !this.state.delete, delete_id: id }, () => {
      if (this.state.delete) {
        document.body.classList.add("modal-open");
        document.body.classList.add("modal-style");
      } else {
        document.body.classList.remove("modal-open");
        document.body.classList.remove("modal-style");
      }
    });
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  componentDidMount = () => {
    const animateRef = [
      this.animateRef0,
      this.animateRef1,
      this.animateRef2,
      // this.animateRef12,
    ];

    animateRef.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
    this.props.getFoumCatergory();
    this.props.searchForumTopic();
    if (getToken("refresh")) {
      this.props.getMyForumTopic();
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.continuousStart();
    }
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      this.ref.current.complete();
    }
  };

  handlePageClick = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.setState({ page: e.selected + 1 }, () =>
      this.props.searchForumTopic({ page: this.state.page })
    );
  };

  handleSearch = (e) => {
    this.setState({ keyword: e.target.value }, () => {
      this.props.searchForumTopic({ keyword: this.state.keyword });
    });
  };

  toggleForum = (e, edit, editData) => {
    this.setState(
      { forumTopic: !this.state.forumTopic, edit, editData },
      () => {
        if (this.state.forumTopic) {
          document.body.classList.add("modal-open");
          document.body.classList.add("modal-style");
        } else {
          document.body.classList.remove("modal-open");
          document.body.classList.remove("modal-style");
        }
      }
    );
  };

  setEditDataNull = () => {
    this.setState({ editData: null });
  };

  render() {
    const forumCategory = this.props.forumCategory;
    const forumTopic = this.props.forumTopic;
    const forumList = getToken("refresh")
      ? this.props.forumTopic
      : this.props.forumTopic &&
        this.props.forumTopic.length > 0 &&
        this.props.forumTopic.filter((el) => el.hostType === "admin");
    return (
      <>
        <Loader laoderRef={this.ref} />
        <Header menu={this.state.menu} toggleMenu={this.toggleMenu} />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="inner-hero-container position-relative d-flex align-items-end">
          <div class="inner-hero-bg"></div>
          <div class="container">
            <div class="row">
              <div
                class="col-lg-7 col-md-8 animate-child"
                ref={(el) => (this.animateRef0 = el)}
              >
                <div class="inner-hero-content">
                  <div class="inner-hero-text">
                    <h5>What’s Inspiring You Today?</h5>
                    <p>
                      <span style={{ fontWeight: "bolder" }}>
                        Ask a Question, Tell a Story, Share your Perspective.
                      </span>
                      <br></br>
                      <br></br>
                      Here is where we can be vulnerable and have honest and
                      loving discourse.<br></br>
                      <br></br>
                      Explore our Forum topics that interest you, use this as a
                      space to interact meaningfully with your GOLDNLink
                      community members. You can even start a Forum of your own!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-container forum-container">
          <div class="container">
            {forumList && forumList.length > 0 ? (
              <div class="row">
                <div class="col-lg-12">
                  <div
                    id="forum"
                    class="header-with-search-box animate-child d-flex align-items-center"
                    ref={(el) => (this.animateRef1 = el)}
                  >
                    <div class="heading">
                      <h3 class="mb-0">Forums</h3>
                    </div>
                    <div class="search-box ms-auto">
                      <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">
                          <img src={search} alt="" />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Search forums"
                          aria-label="Username"
                          aria-describedby="addon-wrapping"
                          value={this.state.keyword}
                          onChange={(e) => this.handleSearch(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="forum-slider-container"
                    ref={(el) => (this.animateRef2 = el)}
                  >
                    <Swiper
                      modules={[Navigation]}
                      slidesPerView={5}
                      spaceBetween={30}
                      navigation={{
                        nextEl: this.nextRef,
                        prevEl: this.prevRef,
                      }}
                      breakpoints={{
                        320: {
                          spaceBetween: 10,
                          slidesPerView: 2,
                        },
                        640: {
                          spaceBetween: 10,
                          slidesPerView: 3,
                        },
                        991: {
                          slidesPerView: 4,
                        },
                        1024: {
                          slidesPerView: 5,
                        },
                      }}
                      className="swiper five-column-slider p-0"
                    >
                      <div className="swiper-wrapper" style={{ padding: "0" }}>
                        {forumCategory &&
                          forumCategory
                            .filter((el) => el.isActive == true)
                            .map((el, i) => (
                              <SwiperSlide
                                className={
                                  this.state.category === el.name
                                    ? "swiper-slide active-cat"
                                    : "swiper-slide"
                                }
                                onClick={() => {
                                  this.setState({ category: el.name });
                                  if (this.state.category === el.name) {
                                    this.props.searchForumTopic();
                                    this.setState({ category: "" }, () => {});
                                  } else {
                                    this.props.searchForumTopic(
                                      {
                                        category: el._id,
                                      },
                                      () => {}
                                    );
                                  }
                                }}
                                key={i}
                              >
                                <a
                                  href="#forum"
                                  class="fs-box"
                                  style={{
                                    background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${
                                      el.image &&
                                      process.env.REACT_APP_CDN +
                                        el.image.original
                                    })`,
                                  }}
                                >
                                  <h3>{el.name}</h3>
                                </a>
                              </SwiperSlide>
                            ))}
                      </div>
                      <div
                        className="swiper-button-next2"
                        ref={(el) => (this.nextRef = el)}
                      ></div>
                      <div
                        className="swiper-button-prev2"
                        ref={(el) => (this.prevRef = el)}
                      ></div>
                    </Swiper>
                    <p class="result-show-title">
                      Showing Results for{" "}
                      <span>
                        {this.state.category ? this.state.category : "All"}
                      </span>
                    </p>
                  </div>
                  <ul class="card-list d-flex flex-wrap">
                    {forumList &&
                      forumList.map((el, i) => (
                        <ForumCard
                          history={this.props.history}
                          data={el}
                          i={i}
                          toggleDelete={this.toggleDelete}
                          toggleForum={this.toggleForum}
                          myForumTopic={this.props.myForumTopic}
                        />
                      ))}
                  </ul>
                  <ReactPaginate
                    forcePage={this.state.page - 1}
                    pageClassName={""}
                    pageLinkClassName={""}
                    previousClassName={"cm-prev-btn"}
                    nextClassName={"cm-next-btn"}
                    previousLinkClassName={"btn btn-gray"}
                    nextLinkClassName={"btn btn-outline"}
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.props.pagination.total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={
                      "cm-pagination d-flex justify-content-between"
                    }
                    activeLinkClassName={"active"}
                  />
                </div>
              </div>
            ) : (
              <>
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      id="forum"
                      class="header-with-search-box animate-child d-flex align-items-center"
                      ref={(el) => (this.animateRef1 = el)}
                    >
                      <div class="heading">
                        <h3 class="mb-0">Forums</h3>
                      </div>
                      <div class="search-box ms-auto">
                        <div class="input-group flex-nowrap">
                          <span class="input-group-text" id="addon-wrapping">
                            <img src={search} alt="" />
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search forums"
                            aria-label="Username"
                            aria-describedby="addon-wrapping"
                            value={this.state.keyword}
                            onChange={(e) => this.handleSearch(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="forum-slider-container"
                      ref={(el) => (this.animateRef2 = el)}
                    >
                      <Swiper
                        modules={[Navigation]}
                        slidesPerView={5}
                        spaceBetween={30}
                        navigation={{
                          nextEl: this.nextRef,
                          prevEl: this.prevRef,
                        }}
                        breakpoints={{
                          320: {
                            spaceBetween: 10,
                            slidesPerView: 2,
                          },
                          640: {
                            spaceBetween: 10,
                            slidesPerView: 3,
                          },
                          991: {
                            slidesPerView: 4,
                          },
                          1024: {
                            slidesPerView: 5,
                          },
                        }}
                        className="swiper five-column-slider p-0"
                      >
                        <div
                          className="swiper-wrapper"
                          style={{ padding: "0" }}
                        >
                          {forumCategory &&
                            forumCategory
                              .filter((el) => el.isActive == true)
                              .map((el, i) => (
                                <SwiperSlide
                                  className={
                                    this.state.category === el.name
                                      ? "swiper-slide active-cat"
                                      : "swiper-slide"
                                  }
                                  onClick={() => {
                                    this.setState({ category: el.name });
                                    if (this.state.category === el.name) {
                                      this.props.searchForumTopic();
                                      this.setState({ category: "" }, () => {});
                                    } else {
                                      this.props.searchForumTopic(
                                        {
                                          category: el._id,
                                        },
                                        () => {}
                                      );
                                    }
                                  }}
                                  key={i}
                                >
                                  <a
                                    href="#forum"
                                    class="fs-box"
                                    style={{
                                      background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(${
                                        el.image &&
                                        process.env.REACT_APP_CDN +
                                          el.image.small
                                      })`,
                                    }}
                                  >
                                    <h3>{el.name}</h3>
                                  </a>
                                </SwiperSlide>
                              ))}
                        </div>
                        <div
                          className="swiper-button-next2"
                          ref={(el) => (this.nextRef = el)}
                        ></div>
                        <div
                          className="swiper-button-prev2"
                          ref={(el) => (this.prevRef = el)}
                        ></div>
                      </Swiper>
                      <p class="result-show-title">
                        Showing Results for{" "}
                        <span>
                          {this.state.category ? this.state.category : "All"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                {!this.props.loading && (
                  <div class="row">
                    <div class="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2">
                      <div class="cm-no-found ">
                        <div class="no-found-icon">
                          <img src={folderIcon} alt="" />
                        </div>
                        <h3>No Information found!</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Aenean fermentum, nec dis elementum id id.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div class="overlay" onClick={this.toggleMenu} id="overlay"></div>
        <SocialMedia />
        <Footer history={this.props.history} />
        <DeletePopup
          delete={this.state.delete}
          delete_id={this.state.delete_id}
          toggleDelete={this.toggleDelete}
          title="Forum Topic"
          message="Are you sure you want to delete this event ?"
          deleteApi={this.props.deleteForum}
          searchApi={this.props.searchForumTopic}
        />
        <ForumTopic
          forumTopic={this.state.forumTopic}
          toggleForum={this.toggleForum}
          setEditDataNull={this.setEditDataNull}
          edit={this.state.edit}
          editData={this.state.editData}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  forumCategory: getForum(state).forumCategory,
  forumTopic: getForum(state).forumTopic,
  loading: getForum(state).loading,
  pagination: getForum(state).pagination,
  myForumTopic: getForum(state).myForumTopic,
});
const mapDispatchToProps = (dispatch) => ({
  getFoumCatergory: (data) => dispatch(getFoumCatergory(data)),
  searchForumTopic: (param, callback) =>
    dispatch(searchForumTopic(param, callback)),
  deleteForum: (id, callback) => dispatch(deleteForum(id, callback)),
  getMyForumTopic: (params) => dispatch(getMyForumTopic(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forum));
