import React, { Component } from "react";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";

class DeletePopup extends Component {
  handleDelete = () => {
    this.props.deleteApi(this.props.delete_id, (res) => {
      if (res && res.status === 200) {
        this.props.searchApi();
        this.props.toggleDelete();
        toast(<AlertSuccess message={"Deleted Successfully"} />);
      } else {
        toast(<AlertError message={res.data.message} />);
      }
    });
  };
  render() {
    return (
      <>
        <div
          class={
            this.props.delete
              ? "modal fade alert-popup show d-block"
              : "modal fade alert-popup d-none"
          }
          id="alert-popup"
          tabindex="-1"
          aria-labelledby="alert-popup"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header" onClick={this.props.toggleDelete}>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body">
                <div class="modal-alert-box">
                  <h4>{`Delete ${this.props.title}`}</h4>
                  <p>{this.props.message}</p>
                </div>
                <div class="modal-action-btn">
                  <a
                    href="javascript:void(0)"
                    class="btn btn-delete btn-md"
                    onClick={() => this.handleDelete()}
                  >
                    Delete
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-cancel"
                    data-bs-dismiss="modal"
                    onClick={this.props.toggleDelete}
                  >
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.delete && <div className="modal-backdrop fade show"></div>}
      </>
    );
  }
}

export default DeletePopup;
