import React from "react";
import close from "../../../include/images/close.svg";
const AlertClose = () => {
  return (
    <span className="alert-right">
      <a href="#" className="alert-close" data-dismiss="alert">
        <img src={close} alt="" />
      </a>
    </span>
  );
};

export default AlertClose;
