import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "users",
  initialState: {
    profile: {},
    loading: false,
    users: [],
    pagination: {},
    detail: {}
  },
  reducers: {
    profileRequested: (misc, action) => {
      misc.loading = true;
    },
    profileReceived: (misc, action) => {
      misc.profile = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    profileRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    userRequested: (misc, action) => {
      misc.loading = true;
    },
    userReceived: (misc, action) => {
      misc.users = action.payload.data;
      misc.pagination = action.payload.pagination;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userRequestFailed: (misc, action) => {
      misc.loading = false;
    },
    userDetailRequested: (misc, action) => {
      misc.loading = true;
    },
    userDetailReceived: (misc, action) => {
      misc.detail = action.payload.data;
      misc.lastFetch = Date.now();
      misc.loading = false;
    },
    userDetailRequestFailed: (misc, action) => {
      misc.loading = false;
    },
  },
});

export const {
  profileRequestFailed,
  profileReceived,
  profileRequested,
  userRequested,
  userReceived,
  userRequestFailed,
  userDetailRequested,
  userDetailReceived,
  userDetailRequestFailed

} = slice.actions;
export default slice.reducer;

// Action Creators
const userUrl = "users/";
const authUrl = "auth/"

export const checkEmail = (email, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "email/" + email,
      method: "GET",
      callback,
    })
  );
};

export const resendOtp = (callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "resendOtp",
      method: "PUT",
      callback,
    })
  );
};

export const signUp = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl,
      method: "POST",
      data,
      callback,
    })
  );
};

export const signIn = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "users",
      method: "POST",
      data,
      callback,
    })
  );
};
export const forgotPassword = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "forgotPassword",
      method: "POST",
      data,
      callback,
    })
  );
};

export const resetPassword = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "resetPassword/" + id,
      method: "POST",
      data,
      callback,
    })
  );
};

export const changePasswordUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: authUrl + "changePassword",
      method: "POST",
      data,
      callback,
    })
  );
};

export const verifyEmail = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "verifyOtp",
      method: "PUT",
      data,
      callback,
    })
  );
};
export const getProfile = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "me",
      method: "GET",
      onStart: profileRequested.type,
      onSuccess: profileReceived.type,
      onError: profileRequestFailed.type,
    })
  );
};

export const searchUser = (params, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "search",
      params,
      method: "GET",
      onStart: userRequested.type,
      onSuccess: userReceived.type,
      onError: userRequestFailed.type,
      callback
    })
  );
};

export const getUserDetail = (id) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "details/" + id,
      method: "GET",
      onStart: userDetailRequested.type,
      onSuccess: userDetailReceived.type,
      onError: userDetailRequestFailed.type,
    })
  );
};

export const updateProfileUser = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "me",
      method: "PUT",
      data,
      callback,
    })
  );
};

export const uploadProfilePicture = (data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "uploadProfilePicture",
      method: "POST",
      data,
      callback,
    })
  );
};

export const addToRodolex = (id, data, callback) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: userUrl + "addToRobolex/" + id + "/" + data,
      method: "POST",
      data,
      callback,
    })
  );
};

export const getUser = createSelector(
  (state) => state.entities.users,
  (users) => users
);
