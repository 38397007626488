import React, { createRef } from "react";
import Form from "../common/form/form";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../common/header";
import Sidebar from "../common/sidebar";
import gsap from "gsap";
import Footer from "../common/footer";
import SeeYourPower from "../common/seeYourPower";
import sbi from "../../include/images/sbi.png";
import paypal from "../../include/images/paypal.png";
import visa from "../../include/images/visa.png";
import Joi from "joi-browser";
import SubmitBtn from "../common/form/submitBtn";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import { createDonation } from "../../store/donate";

class Donate extends Form {
  animateRef1 = createRef(null);
  animateRef2 = createRef(null);
  state = {
    menu: false,
    loading: false,
    data: {
      name: "",
      email: "",
      phone: "",
    },
    errors: {},
  };

  schema = {
    name: Joi.string()
      .required()
      .label("Donor Name")
      .error(() => {
        return { message: "Donor Name is required" };
      }),
    email: Joi.string()
      .required()
      .label("Donor Email")
      .error(() => {
        return { message: "Donor Email is required" };
      }),
    phone: Joi.string()
      .required()
      .label("Donor Phone Number")
      .error(() => {
        return { message: "Donor Phone Number is required" };
      }),
  };

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  componentDidMount = () => {
    window.scrollTo({ top: "0", behavior: "smooth" });
    const animateRef = [this.animateRef1, this.animateRef2];

    animateRef.forEach((cb) =>
      gsap.to(cb, {
        scrollTrigger: {
          trigger: cb,
          once: true,
          start: "top 90%",
          toggleClass: "animated",
        },
      })
    );
  };

  doSubmit = () => {
    this.setState({ loading: true });
    const data = { ...this.state.data };
    const payload = {
      name: data.name,
      email: data.email,
      phone: data.phone,
    };
    this.props.createDonation(payload, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false });
    if (res.status === 200) {
      window.location.href =
        "https://www.paypal.com/donate/?hosted_button_id=5X7F6UNRQE8CN";
    } else {
      toast(<AlertError message={res.data.message} />);
    }
  };

  render() {
    return (
      <>
        <Header
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true}
        />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="donate-page-container overflow-hidden">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="dp-box  d-lg-flex flex-lg-wrap">
                  <div
                    class="dp-left animate-child"
                    ref={(el) => (this.animateRef1 = el)}
                  >
                    <h4>Donate</h4>
                    <p>
                      The GOLDNLink is a Non-Profit organization that relies on
                      the generosity of its family members. Please consider
                      donating via PayPal, Visa, or Cryptocurrency.
                    </p>
                    <p>
                      Please enter your information below to make a donation.
                    </p>
                  </div>
                  <div class="dp-right">
                    <div class="donate-shape d-none d-lg-block"></div>

                    <div class="half-circle-shape "></div>
                    <form
                      onSubmit={this.handleSubmit}
                      class="contact-form-box animate-child"
                      ref={(el) => (this.animateRef2 = el)}
                    >
                      {this.renderInput("name", "Donor Name")}
                      {this.renderInput("email", "Donor Email")}
                      {this.renderInput("phone", "Donor Phone Number")}
                      <div class="form-btn">
                        <SubmitBtn
                          btnClass="btn btn-primary btn-block"
                          label="Send"
                          loading={this.state.loading}
                        />
                      </div>
                      <ul class="donate-payment-links d-flex align-items-center">
                        <li>
                          <a href="#!">
                            <img src={sbi} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <img src={paypal} alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <img src={visa} alt="" />
                          </a>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SeeYourPower />
        <Footer history={this.props.history} />
        <div class="overlay" onClick={this.toggleMenu} id="overlay"></div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createDonation: (data, callback) => dispatch(createDonation(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(Donate));
