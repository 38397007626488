import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "forum",
    initialState: {
        loading: false,
        pagination: {},
        forumCategory: [],
        lastFetch: null,
        forumTopic: [],
        myForumTopic: [],
        detail: {}
    },
    reducers: {
        forumCatRequested: (misc, action) => {
            misc.loading = true;
        },
        forumCatReceived: (misc, action) => {
            misc.forumCategory = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        forumCatRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        forumTopicRequested: (misc, action) => {
            misc.loading = true;
        },
        forumTopicReceived: (misc, action) => {
            misc.forumTopic = action.payload.data;
            misc.pagination = action.payload.pagination
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        forumTopicRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        myForumTopicRequested: (misc, action) => {
            misc.loading = true;
        },
        myForumTopicReceived: (misc, action) => {
            misc.myForumTopic = action.payload.data;
            // misc.pagination = action.payload.pagination
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        myForumTopicRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        forumTopicDetailRequested: (misc, action) => {
            misc.loading = true;
        },
        forumTopicDetailReceived: (misc, action) => {
            misc.detail = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        forumTopicDetailRequestFailed: (misc, action) => {
            misc.loading = false;
        },

    },
});

export const {
    forumCatRequested,
    forumCatReceived,
    forumCatRequestFailed,
    forumTopicRequested,
    forumTopicReceived,
    forumTopicRequestFailed,
    forumTopicDetailRequested,
    forumTopicDetailReceived,
    forumTopicDetailRequestFailed,
    myForumTopicRequested,
    myForumTopicReceived,
    myForumTopicRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "forumTopics/";

export const getMyForumTopic = (params) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "me",
            params,
            method: "GET",
            onStart: myForumTopicRequested.type,
            onSuccess: myForumTopicReceived.type,
            onError: myForumTopicRequestFailed.type,
        })
    );
};

export const getFoumCatergory = (params) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "forumCategories",
            params,
            method: "GET",
            onStart: forumCatRequested.type,
            onSuccess: forumCatReceived.type,
            onError: forumCatRequestFailed.type,
        })
    );
};

export const uploadGridImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "uploadGridImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const uploadCoverImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "uploadCoverImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const addForumTopic = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url,
            method: "POST",
            data,
            callback,
        })
    );
};

export const editForumTopic = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const searchForumTopic = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "search",
            params,
            method: "GET",
            onStart: forumTopicRequested.type,
            onSuccess: forumTopicReceived.type,
            onError: forumTopicRequestFailed.type,
            callback
        })
    );
};
export const getTopicDetail = (id) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + "details/" + id,
            method: "GET",
            onStart: forumTopicDetailRequested.type,
            onSuccess: forumTopicDetailReceived.type,
            onError: forumTopicDetailRequestFailed.type,
        })
    );
};

export const deleteForum = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            method: "DELETE",
            callback,
        })
    );
};

export const getForum = createSelector(
    (state) => state.entities.forum,
    (forum) => forum
);
