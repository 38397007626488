import React, { Component, createRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

class PhotoModal extends Component {
  nextRef = createRef(null);
  prevRef = createRef(null);
  render() {
    return (
      <div
        class={
          this.props.photoModal
            ? "modal fade custom-modal3 show d-block"
            : "modal fade custom-modal3 d-none"
        }
        id="photosPopup"
        tabIndex="-1"
        aria-labelledby="photosPopup"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <a
              href="#"
              type="button"
              class="btnClose"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={this.props.togglePhoto}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.8107 11.75L19.2803 18.2197C19.5732 18.5126 19.5732 18.9874 19.2803 19.2803C18.9874 19.5732 18.5126 19.5732 18.2197 19.2803L11.75 12.8107L5.28033 19.2803C4.98744 19.5732 4.51256 19.5732 4.21967 19.2803C3.92678 18.9874 3.92678 18.5126 4.21967 18.2197L10.6893 11.75L4.21967 5.28033C3.92678 4.98744 3.92678 4.51256 4.21967 4.21967C4.51256 3.92678 4.98744 3.92678 5.28033 4.21967L11.75 10.6893L18.2197 4.21967C18.5126 3.92678 18.9874 3.92678 19.2803 4.21967C19.5732 4.51256 19.5732 4.98744 19.2803 5.28033L12.8107 11.75Z" />
              </svg>
            </a>
            <div class="post-media-popup">
              <Swiper
                modules={[Navigation]}
                loop={true}
                navigation={{
                  nextEl: this.nextRef,
                  prevEl: this.prevRef,
                }}
                className="swiper pmb-slide photos"
              >
                <div class="swiper-wrapper post-media-list">
                  {this.props.photo &&
                    this.props.photo.length > 0 &&
                    this.props.photo.map((el) => (
                      <SwiperSlide className="swiper-slide post-media-item">
                        <img
                          src={
                            el.urls &&
                            process.env.REACT_APP_CDN + el.urls.original
                          }
                          alt=""
                        />
                      </SwiperSlide>
                    ))}
                </div>
                <div
                  class="swiper-button-next"
                  ref={(el) => (this.nextRef = el)}
                ></div>
                <div
                  class="swiper-button-prev"
                  ref={(el) => (this.prevRef = el)}
                >
                  {" "}
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PhotoModal;
