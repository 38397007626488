import React from "react";
import { Link } from "react-router-dom";
import Form from "../common/form/form";
import Joi from "joi-browser";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  passwordInvalid,
  passwordInvalidMax,
  passwordRequired,
} from "../common/misc";
import { signIn } from "../../store/users";
import { toast } from "react-toastify";
import AlertError from "../common/alert/alertError";
import { setToken } from "../../utils/localStorageServices";
import SubmitBtn from "../common/form/submitBtn";
import lineCircle from "../../include/images/line-circle.png";
import memberBg from "../../include/images/member-bg.png";

class Signin extends Form {
  state = {
    loading: false,
    disabled: false,
    label: "Login",
    data: {
      email: "",
      password: "",
    },
    errors: {
      email: "",
      password: "",
    },
  };
  componentDidMount = () => {
    document.body.classList.add("p-0");
  };
  componentWillUnmount = () => {
    document.body.classList.remove("p-0");
  };
  schema = {
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label("Email")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Email is required";
              break;
            case "string.email":
              err.message = "Email is invalid";
              break;
            case "string.max":
              err.message = "Email is invalid";
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .min(8)
      .max(25)
      .required()
      .label("Password")
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = passwordRequired;
              break;

            case "string.min":
              err.message = passwordInvalid;
              break;

            case "string.max":
              err.message = passwordInvalidMax;
              break;

            default:
              break;
          }
        });
        return errors;
      }),
  };

  doSubmit = () => {
    this.setState({ loading: true, disabled: true, lable: "" });
    const data = { ...this.state.data };
    this.props.signIn(data, this.callback);
  };

  callback = (res) => {
    this.setState({ loading: false, disabled: false, label: "Login" });
    if (res.status === 200) {
      localStorage.setItem("profile", JSON.stringify(res.data.data));
      localStorage.setItem("otp", JSON.stringify(res.data.data.otp));
      const { refreshToken, jwt } = res.data.data;
      setToken(refreshToken, jwt);
      if (res.data.data.emailVerified) {
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.login === "login"
        ) {
          this.props.history.push({
            pathname: "/forum-detail/" + this.props.location.state.id,
          });
        } else {
          this.props.history.push("/dashboard");
        }
      } else {
        this.props.history.push("/verify-email");
      }
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };
  render() {
    return (
      <div class="member-container d-lg-flex">
        <div class="member-left">
          <div class="member-bg">
            <img src={memberBg} alt="" />
            <div class="circle-img">
              <img src={lineCircle} alt="" />
            </div>
          </div>
          <div class="member-content-box">
            <h6
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              Welcome to
            </h6>
            <h3
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/")}
            >
              THE GOLDN <span>link</span>
            </h3>
            <p>
              We’re on a mission to empower <span>Black</span> and{" "}
              <span>Brown</span> women around the world and help them build the
              necessary connections to change their circumstances.
            </p>
          </div>
        </div>
        <div class="member-right">
          <div class="member-form-container d-flex align-items-center justify-content-center">
            <div class="member-form-box">
              <h1>Login</h1>
              <form onSubmit={this.handleSubmit}>
                {this.renderInput("email", "Email Address", "email")}

                <div class="form-group">
                  {this.renderPasswordInput(
                    "password",
                    "Password",
                    "password",
                    true
                  )}
                  <div class="forgot-password">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                </div>

                <div class="form-group">
                  {/* <input
                    type="submit"
                    name="Login"
                    class="btn btn-primary btn-block "
                    value="Login"
                  /> */}
                  <SubmitBtn
                    loading={this.state.loading}
                    btnClass="btn btn-primary btn-block"
                    label={this.state.label}
                    disabled={this.state.disabled}
                  />
                </div>
              </form>
              <div class="already-member text-center">
                <p>
                  Not a member? <Link to="/sign-up">Sign Up</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signIn: (email, callback) => dispatch(signIn(email, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(Signin));
