import React, { Component } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

class DateInput extends Component {
  state = {
    formclass: "form-group custom-input show on",
  };

  handleFocus = () => {
    this.setState({ formclass: "form-group custom-input show on" });
  };

  handleBlur = (e) => {
    const classname = e.target.value
      ? "form-group custom-input show on"
      : "form-group custom-input show on";
    this.setState({ formclass: classname });
  };

  componentDidMount() {
    //this.initDatepicker();
    const classname = "form-group custom-input show on";
    this.setState({ formclass: classname });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? "form-group custom-input show on"
        : "form-group custom-input show on";
      this.setState({ formclass: classname });
    }
  }

  render() {
    const {
      name,
      label,
      error,
      minDate,
      disabled,
      minTime,
      onChange,
      selected,
      ...rest
    } = this.props;
    return (
      <>
        <div className={this.state.formclass}>
          <label htmlFor={label}>{label}</label>
          {/* <DatePicker
                autoComplete="off"
            yearItemNumber="10"
            minDate={minDate}
            name={name}
            id={label}
            className={
              error
                ? "form-control datepicker error"
                : "form-control datepicker"
            }
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            dateFormat="MM/dd/yyyy"
            {...rest}
          /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={selected}
              onChange={onChange}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="dd/MM/yyyy"
              {...rest}
            />
          </LocalizationProvider>
          {error && <label className="error">{error}</label>}
        </div>
      </>
    );
  }
}

export default DateInput;
