import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "comment",
    initialState: {
        loading: false,
        pagination: {},
        comment: [],
        lastFetch: null,
    },
    reducers: {
        commentRequested: (misc, action) => {
            misc.loading = true;
        },
        commentReceived: (misc, action) => {
            misc.comment = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        commentRequestFailed: (misc, action) => {
            misc.loading = false;
        },

    },
});

export const {
    commentRequested,
    commentRequestFailed,
    commentReceived
} = slice.actions;
export default slice.reducer;

// Action Creators
const url = "comments/";

export const getComments = (id, params) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url + id,
            params,
            method: "GET",
            onStart: commentRequested.type,
            onSuccess: commentReceived.type,
            onError: commentRequestFailed.type,
        })
    );
};

export const createComment = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: url,
            method: "POST",
            data,
            callback,
        })
    );
};



export const getComment = createSelector(
    (state) => state.entities.comment,
    (comment) => comment
);
