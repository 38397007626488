import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "misc",
    initialState: {
        loading: false,
        media: {},
        achievement: []
    },
    reducers: {
        mediaRequested: (misc, action) => {
            misc.loading = true;
        },
        mediaReceived: (misc, action) => {
            misc.media = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        mediaRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        achievementRequested: (misc, action) => {
            misc.loading = true;
        },
        achievementReceived: (misc, action) => {
            misc.achievement = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        achievementRequestFailed: (misc, action) => {
            misc.loading = false;
        },
    },
});

export const {
    mediaReceived,
    mediaRequested,
    mediaRequestFailed,
    achievementRequested,
    achievementReceived,
    achievementRequestFailed
} = slice.actions;
export default slice.reducer;

// Action Creators
const contactUrl = "contactUs/"


export const contactUs = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: contactUrl,
            method: "POST",
            data,
            callback,
        })
    );
};


export const getMediaUrl = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "settings/mediaUrls",
            method: "GET",
            onStart: mediaRequested.type,
            onSuccess: mediaReceived.type,
            onError: mediaRequestFailed.type
        })
    );
};


export const getAchievement = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: "notableAchievements",
            method: "GET",
            onStart: achievementRequested.type,
            onSuccess: achievementReceived.type,
            onError: achievementRequestFailed.type
        })
    );
};

export const getMisc = createSelector(
    (state) => state.entities.misc,
    (misc) => misc
);
