import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getToken } from "../../utils/localStorageServices";
import close from "../../include/images/close.svg";

class Sidebar extends Component {
  render() {
    const media = this.props.media;
    return (
      <div class="sidebar">
        <div class="sidebar-box">
          <div class="close-icon" id="close" onClick={this.props.toggleMenu}>
            <img src={close} alt="" />
          </div>
          <ul class="menu-list">
            <li class="menu-item">
              <a
                href="javascript:void(0)"
                class="menu-link"
                onClick={() => {
                  this.props.toggleMenu();
                  setTimeout(() => this.props.history.push("/"), 50);
                }}
              >
                Home
              </a>
            </li>
            <li class="menu-item">
              <a
                href="javascript:void(0)"
                class="menu-link"
                onClick={() => {
                  this.props.toggleMenu();
                  setTimeout(
                    () => this.props.history.push("/community-member"),
                    50
                  );
                }}
              >
                Community
              </a>
            </li>
            <li class="menu-item">
              <a
                href="javascript:void(0)"
                class="menu-link"
                onClick={() => {
                  this.props.toggleMenu();
                  setTimeout(() => this.props.history.push("/forum"), 50);
                }}
              >
                Forum
              </a>
            </li>
            <li class="menu-item">
              <a
                href="javascript:void(0)"
                class=" menu-link has-menu collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#events-collapse"
                aria-expanded="false"
                id="wrapper"
                onClick={() => {
                  this.props.toggleMenu();
                  setTimeout(() => this.props.history.push("/events"), 50);
                }}
              >
                Events
              </a>
            </li>
            {getToken("refresh") ? (
              <li class="menu-item">
                <a
                  href="javascript:void(0)"
                  class="menu-link"
                  onClick={() => {
                    this.props.toggleMenu();
                    setTimeout(() => this.props.history.push("/dashboard"), 50);
                  }}
                >
                  Profile
                </a>
              </li>
            ) : (
              <li class="menu-item">
                <a
                  href="javascript:void(0)"
                  class="menu-link"
                  onClick={() => {
                    this.props.toggleMenu();
                    setTimeout(() => this.props.history.push("/sign-in"), 50);
                  }}
                >
                  Log In
                </a>
              </li>
            )}
          </ul>
          <ul class="sidebar-bott-menu">
            <li class="sbm-item">
              <a
                href="javascript:void(0)"
                onClick={() => {
                  this.props.toggleMenu();
                  setTimeout(() => this.props.history.push("/donate"), 50);
                }}
              >
                Donate
              </a>
            </li>
            <li class="sbm-item">
              <a
                href="javascript:void(0)"
                onClick={() => {
                  this.props.toggleMenu();
                  setTimeout(() => this.props.history.push("/contact"), 50);
                }}
              >
                Contact Us
              </a>
            </li>
          </ul>
          <ul class="social-list d-flex align-items-center">
            <li>
              <a href={media && media.facebook} target="_blank">
                <i class="fab fa-facebook-square"></i>
              </a>
            </li>
            <li>
              <a href={media && media.youtube} target="_blank">
                <i class="fab fa-youtube"></i>
              </a>
            </li>
            <li>
              <a href={media && media.instagram} target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
