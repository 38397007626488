import React, { createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  addForumTopic,
  editForumTopic,
  getForum,
  getFoumCatergory,
  searchForumTopic,
  uploadCoverImage,
  uploadGridImage,
} from "../../store/forum";
import Form from "../common/form/form";
import SubmitBtn from "../common/form/submitBtn";
import CreatableSelect from "react-select/creatable";
import Loader from "../common/loader";
import _ from "lodash";
import Joi from "joi-browser";
import isURL from "validator/lib/isURL";
import { toast } from "react-toastify";
import AlertSuccess from "../common/alert/alertSuccess";
import AlertError from "../common/alert/alertError";
import modalClose from "../../include/images/modal-close.svg";
import plusIcon from "../../include/images/plus-icon.svg";
import trash from "../../include/images/trash.svg";

class ForumTopic extends Form {
  ref = createRef(null);
  state = {
    loading: false,
    disabled: false,
    tags: [],
    category: "",
    gridImage: {},
    coverImage: {},
    vidType: "",
    data: {
      topic: "",
      shortDescription: "",
      longDesription: "",
      url: "",
    },
    errors: {},
    catName: "",
  };

  schema = {
    topic: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Topic is required.";
              break;
          }
        });
        return errors;
      }),
    shortDescription: Joi.string()
      .max(140)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Summary is required.";
              break;
            case "string.max":
              err.message =
                "Summary should not be greater than 140 characters.";
              break;
          }
        });
        return errors;
      }),
    longDesription: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Forum Text is required.";
              break;
          }
        });
        return errors;
      }),
    url: Joi.string().allow(""),
  };

  customStyles = {
    input: (provided, state) => ({
      ...provided,
      height: "60px !important",
      position: "relative",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(95, 50, 187, 10%)",
        color: "#621ac0",
      },

      color: state.isSelected ? "#621ac0" : "black",
      backgroundColor: state.isSelected
        ? "rgb(95, 50, 187, 10%)"
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      border: "0",
      borderRadius: "0",
      backgroundColor: "#F5F5F5",
      borderColor: state.isFocused ? "#ebc7b0" : "#bdbdbd",
      boxShadow: "0 !important",
      transition: "border .15s ease-in-out,box-shadow .15s ease-in-out",
      "&:hover": {
        border: "1px solid #ebc7b0",
      },
      "&:focus": {
        border: "1px solid #ebc7b0",
      },
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#000",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#fff",
      ":hover": {
        backgroundColor: "#000",
        color: "white",
      },
    }),
  };
  componentDidMount = () => {
    this.props.getFoumCatergory();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.forumTopic &&
      this.props.forumTopic !== prevProps.forumTopic
    ) {
      this.props.getFoumCatergory();
    }
  };

  uploadGridFile = async (e) => {
    this.ref.current.continuousStart();
    this.setState({
      loading: true,
      disabled: true,
    });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadGridImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ gridImage: res.data.data });
      delete errors.gridImage;
      this.setState({
        loading: false,
        errors,
        disabled: false,
      });
      this.ref.current.complete();
    });
  };

  uploadCoverFile = async (e) => {
    this.ref.current.continuousStart();
    this.setState({
      loading: true,
      disabled: true,
    });
    const file = e.target.files[0];
    const data = new FormData();
    data.append("image", file);

    this.props.uploadCoverImage(data, (res) => {
      const errors = { ...this.state.errors };
      this.setState({ coverImage: res.data.data });
      delete errors.coverImage;
      this.setState({
        loading: false,
        errors,
        disabled: false,
      });
      this.ref.current.complete();
    });
  };

  doSubmit = () => {
    this.setState({ loading: true, disabled: true });
    const data = { ...this.state.data };
    const errors = { ...this.state.errors };
    if (!_.isEmpty(data.url) && !isURL(data.url)) {
      errors.url = "Please enter a valid URL";
    }
    if (_.isEmpty(this.state.gridImage)) {
      errors.gridImage = "Grid Image is required";
    }
    if (_.isEmpty(this.state.coverImage)) {
      errors.coverImage = "Cover Image is required";
    }
    if (_.isEmpty(this.state.tags)) {
      errors.tags = "Tags is required";
    }

    if (_.isEmpty(this.state.category)) {
      errors.category = "Category is required";
    }

    this.setState({ errors, loading: false, disabled: false });
    let payload = {
      name: data.topic,
      category: this.state.category,
      tags: this.state.tags && this.state.tags.map((el) => el.value),
      shortDescription: data.shortDescription,
      longDescription: data.longDesription,
      gridImage: this.state.gridImage,
      coverImage: this.state.coverImage,
      // video: {
      //   type: this.state.vidType,
      //   url: data.url,
      // },
    };
    if (this.state.vidType !== "") {
      payload.video = {
        type: this.state.vidType,
        url: data.url,
      };
    }

    if (_.isEmpty(this.state.errors)) {
      if (this.props.edit) {
        this.props.editForumTopic(
          this.props.editData._id,
          payload,
          this.callback
        );
      } else {
        this.props.addForumTopic(payload, this.callback);
      }
    }
  };

  callback = (res) => {
    this.setState({ loading: false, disabled: false });
    if (res.status === 200) {
      this.setState({
        loading: false,
        disabled: false,
        tags: [],
        category: "",
        gridImage: {},
        coverImage: {},
        vidType: "",
        data: {
          topic: "",
          shortDescription: "",
          longDesription: "",
          url: "",
        },
        errors: {},
        catName: "",
      });
      this.props.toggleForum();
      this.props.searchForumTopic();
      return toast(<AlertSuccess message={"Information Saved"} />);
    } else {
      return toast(<AlertError message={res.data.message} />);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.edit == false && this.props.edit !== prevProps.edit) {
      this.setState({
        loading: false,
        disabled: false,
        tags: [],
        category: "",
        gridImage: {},
        coverImage: {},
        vidType: "youtube",
        data: {
          topic: "",
          shortDescription: "",
          longDesription: "",
          url: "",
        },
        errors: {},
        catName: "",
      });
      this.props.setEditDataNull();
    }
    if (
      this.props.edit == true &&
      this.props.editData !== null &&
      this.props.editData !== prevProps.editData
    ) {
      const data = { ...this.state.data };
      let gridImage = {};
      let coverImage = {};
      let tags = [];
      let category = "";
      let vidType = "";
      let catName = "";
      gridImage = this.props.editData && this.props.editData.gridImage;
      coverImage = this.props.editData && this.props.editData.coverImage;
      tags =
        this.props.editData &&
        this.props.editData.tags.map((el) => ({
          label: el,
          value: el,
        }));
      category =
        this.props.editData &&
        this.props.editData.category &&
        this.props.editData.category._id;
      catName =
        this.props.editData &&
        this.props.editData.category &&
        this.props.editData.category.name;
      if (this.props.editData && this.props.editData.video) {
        vidType =
          this.props.editData &&
          this.props.editData.video &&
          this.props.editData.video.type;
        data.url =
          this.props.editData &&
          this.props.editData.video &&
          this.props.editData.video.url;
      }

      data.topic = this.props.editData && this.props.editData.name;

      data.shortDescription =
        this.props.editData && this.props.editData.shortDescription;

      data.longDesription =
        this.props.editData && this.props.editData.longDescription;
      this.setState({
        data,
        gridImage,
        coverImage,
        tags,
        category,
        vidType,
        catName,
      });
    }
  };

  render() {
    const components = {
      DropdownIndicator: null,
    };
    const forumCategory = this.props.forumCategory;
    return (
      <>
        <div
          class={
            this.props.forumTopic
              ? "modal fade full-width-modal bg-white show d-block"
              : "modal fade full-width-modal bg-white d-none"
          }
          id="forumTopic"
          tabindex="-1"
          aria-hidden="true"
        >
          <Loader laoderRef={this.ref} />
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="fwm-content-row">
                <div class="fwm-head  w-100 d-flex flex-wrap position-relative">
                  <h4>Add a Forum Topic</h4>
                  <div
                    class="modal-close-btn"
                    data-bs-dismiss="modal"
                    onClick={this.props.toggleForum}
                  >
                    <img src={modalClose} alt="" />
                  </div>
                </div>
                <form class="fwm-body" onSubmit={this.handleSubmit}>
                  <div class="fwm-row d-lg-flex flex-lg-wrap">
                    <div class="fwm-left-column w-md-50">
                      <div class="fwm-content-box">
                        {this.renderInput("topic", "Topic Name")}
                        <div class="form-group">
                          <label class="form-label">Category</label>
                          <select
                            class="form-select"
                            onChange={(e) => {
                              const errors = { ...this.state.errors };
                              e.preventDefault();
                              if (e.target.value) {
                                this.setState({ category: e.target.value });
                                delete errors.category;
                                this.setState({ errors });
                              } else {
                                errors.type = "Category is required";
                                this.setState({ errors });
                              }
                            }}
                          >
                            <option value="">Select Category</option>
                            {forumCategory &&
                              forumCategory.length > 0 &&
                              forumCategory
                                .filter((el) => el.isActive == true)
                                .map((fo, i) => (
                                  <option
                                    key={i}
                                    value={fo._id}
                                    selected={this.state.catName == fo.name}
                                  >
                                    {fo.name}
                                  </option>
                                ))}
                          </select>
                          {this.state.errors && this.state.errors.category && (
                            <label className="error">
                              {this.state.errors.category}
                            </label>
                          )}
                        </div>
                        <div class="form-group">
                          <label class="form-label">Tags</label>
                          <CreatableSelect
                            components={components}
                            noOptionsMessage={() =>
                              "Type something and press enter..."
                            }
                            isMulti
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            placeholder="Type something and press enter..."
                            name="tags"
                            styles={this.customStyles}
                            value={this.state.tags}
                            onChange={(e) => {
                              const errors = { ...this.state.errors };
                              if (e && e.length > 0) {
                                this.setState({ tags: e });
                                delete errors.tags;
                                this.setState({ errors });
                              } else if (e.length == 0) {
                                this.setState({ tags: "" });
                                errors.tags = "Tags are required";
                                this.setState({ errors });
                              }
                            }}
                          />
                          {this.state.errors && this.state.errors.tags && (
                            <label className="error">
                              {this.state.errors.tags}
                            </label>
                          )}
                        </div>

                        {this.renderTextarea(
                          "shortDescription",
                          "Summary",
                          "100px"
                        )}
                        {this.renderTextarea(
                          "longDesription",
                          "Forum Text",
                          "200px"
                        )}
                      </div>
                    </div>
                    <div class="fwm-right-column w-md-50">
                      <div class="fwm-content-box">
                        <h6>Images & Video</h6>
                        <div class="form-group">
                          <label for="" class="form-label">
                            Grid Image ( Dimension should be approx 400px *
                            300px)
                          </label>
                          {!_.isEmpty(this.state.gridImage) ? (
                            <div class="updated-img-outer d-flex align-items-center">
                              <div class="updated-img-box">
                                <img
                                  src={
                                    this.state.gridImage &&
                                    this.state.gridImage.small &&
                                    process.env.REACT_APP_CDN +
                                      this.state.gridImage.small
                                  }
                                />
                              </div>
                              <div class="updated-text-box">
                                {/* <h4>Change Grid Image</h4> */}
                                <div
                                  class="del-updated-btn"
                                  onClick={() =>
                                    this.setState({ gridImage: {} })
                                  }
                                >
                                  Delete Image
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div class="custom-file-uploader w-100 imgupload">
                                <input
                                  type="file"
                                  name="file"
                                  onChange={this.uploadGridFile}
                                />
                                <img src={plusIcon} alt="" />
                                Add an Image
                              </div>
                              {this.state.errors &&
                                this.state.errors.gridImage && (
                                  <label className="error">
                                    {this.state.errors.gridImage}
                                  </label>
                                )}
                            </>
                          )}
                        </div>
                        <div class="form-group">
                          <label for="" class="form-label">
                            Cover Image (Dimension should be approx 1240px *
                            500px)
                          </label>
                          {!_.isEmpty(this.state.coverImage) ? (
                            <div class="updated-img-outer d-flex align-items-center">
                              <div class="updated-img-box">
                                <img
                                  src={
                                    this.state.coverImage &&
                                    this.state.coverImage.small &&
                                    process.env.REACT_APP_CDN +
                                      this.state.coverImage.original
                                  }
                                />
                              </div>
                              <div class="updated-text-box">
                                <div
                                  class="del-updated-btn"
                                  onClick={() =>
                                    this.setState({ coverImage: {} })
                                  }
                                >
                                  Delete Image
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div class="custom-file-uploader imgupload2">
                                <input
                                  type="file"
                                  name="file"
                                  onChange={this.uploadCoverFile}
                                />
                                <img src={plusIcon} alt="" />
                                Add a cover Image
                              </div>
                              {this.state.errors &&
                                this.state.errors.coverImage && (
                                  <label className="error">
                                    {this.state.errors.coverImage}
                                  </label>
                                )}
                            </>
                          )}
                        </div>
                        <div class="form-group">
                          <label for="name" class="form-label">
                            Video
                          </label>
                          {/* <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault1"
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Upload a Video
                            </label>
                          </div> */}
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              value="youtube"
                              onChange={() => {
                                this.setState({ vidType: "youtube" }, () => {
                                  this.schema.url = Joi.string()
                                    .required()
                                    .error((errors) => {
                                      errors.forEach((err) => {
                                        switch (err.type) {
                                          case "any.empty":
                                            err.message = "URL is required.";
                                            break;
                                        }
                                      });
                                      return errors;
                                    });
                                });
                              }}
                              checked={this.state.vidType === "youtube"}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              Youtube URL
                            </label>
                          </div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault3"
                              value="vimeo"
                              onChange={() => {
                                this.setState({ vidType: "vimeo" }, () => {
                                  this.schema.url = Joi.string()
                                    .required()
                                    .error((errors) => {
                                      errors.forEach((err) => {
                                        switch (err.type) {
                                          case "any.empty":
                                            err.message = "URL is required.";
                                            break;
                                        }
                                      });
                                      return errors;
                                    });
                                });
                              }}
                              checked={this.state.vidType === "vimeo"}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault3"
                            >
                              Vimeo URL
                            </label>
                          </div>
                        </div>
                        {this.renderInput("url", "URL")}
                        <div class="form-group text-end form-button">
                          <SubmitBtn
                            btnClass="btn btn-primary"
                            loading={this.state.loading}
                            label="Submit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  forumCategory: getForum(state).forumCategory,
  loading: getForum(state).loading,
});

const mapDispatchToProps = (dispatch) => ({
  getFoumCatergory: (param) => dispatch(getFoumCatergory(param)),
  uploadGridImage: (data, callback) =>
    dispatch(uploadGridImage(data, callback)),
  uploadCoverImage: (data, callback) =>
    dispatch(uploadCoverImage(data, callback)),
  addForumTopic: (data, callback) => dispatch(addForumTopic(data, callback)),
  editForumTopic: (id, data, callback) =>
    dispatch(editForumTopic(id, data, callback)),
  searchForumTopic: (param) => dispatch(searchForumTopic(param)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForumTopic)
);
