import React, { Component } from "react";
import Header from "../Component/common/header"
import Sidebar from "../Component/common/sidebar";
class ComingSoon extends Component {
  state = {
    menu: false
  }

  toggleMenu = () => {
    this.setState({ menu: !this.state.menu }, () =>
      this.state.menu
        ? document.body.classList.add("menu-open")
        : document.body.classList.remove("menu-open")
    );
  };

  render() {
    return (
      <>
        <Header
          menu={this.state.menu}
          toggleMenu={this.toggleMenu}
          logo={true} />
        <Sidebar toggleMenu={this.toggleMenu} history={this.props.history} />
        <div class="error-page-container">
          <div class="error-content text-center d-flex align-items-center justify-content-between">
            <div
              class="error-bg d-none d-md-block comming-soon"
            ></div>
            <div class="error-content-box">
              <h1 style={{ color: "#fff" }}>Coming soon</h1>
            </div>
          </div>
        </div>
      </>
    )
  }
};

export default ComingSoon;
