import React from "react";
import LoadingBar from "react-top-loading-bar";
class Loader extends React.Component {
  render() {
    return (
      <LoadingBar
        height={4}
        color="#000"
        ref={this.props.laoderRef}
        style={{ margin: "0px", padding: "0px" }}
      />
    );
  }
}

export default Loader;
