import React, { Component } from "react";
import loadingIcon from "../../../include/images/loading-icon-gray.svg";

class SubmitBtn extends Component {
  state = {};
  render() {
    return this.props.onClickHandle ? (
      <button
        onClick={this.props.onClickHandle}
        type="submit"
        className={this.props.btnClass}
        form={this.props.form}
        disabled={this.props.disabled}
      >
        {this.props.loading ? (
          <b className="btn-loader">
            <img
              src={loadingIcon}
              alt="loader"
              className="loader-img fa-spin"
              style={{ height: "15px" }}
            />
          </b>
        ) : (
          this.props.label
        )}
      </button>
    ) : (
      <button
        type="submit"
        className={this.props.btnClass}
        form={this.props.form}
        disabled={this.props.disabled}
      >
        {this.props.loading ? (
          <b className="btn-loader">
            <img
              src={loadingIcon}
              alt="loader"
              className="loader-img fa-spin"
              style={{ height: "15px;" }}
            />
          </b>
        ) : (
          this.props.label
        )}
      </button>
    );
  }
}

export default SubmitBtn;
