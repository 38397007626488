import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { apiCallBegan } from "./api";

const slice = createSlice({
    name: "events",
    initialState: {
        loading: false,
        myEvents: [],
        pagination: {},
        detail: {},
        events: [],
        eventsFeatured: []
    },
    reducers: {

        myEventsRequested: (misc, action) => {
            misc.loading = true;
        },
        myEventsReceived: (misc, action) => {
            misc.myEvents = action.payload.data;
            misc.pagination = action.payload.pagination;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        myEventsRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        eventsRequested: (misc, action) => {
            misc.loading = true;
        },
        eventsReceived: (misc, action) => {
            misc.events = action.payload.data;
            misc.pagination = action.payload.pagination;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        eventsRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        eventsFeatureRequested: (misc, action) => {
            misc.loading = true;
        },
        eventsfeatureReceived: (misc, action) => {
            misc.eventsFeatured = action.payload.data;
            misc.pagination = action.payload.pagination;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        eventsFeatureRequestFailed: (misc, action) => {
            misc.loading = false;
        },
        eventsDetailRequested: (misc, action) => {
            misc.loading = true;
        },
        eventsDetailReceived: (misc, action) => {
            misc.detail = action.payload.data;
            misc.lastFetch = Date.now();
            misc.loading = false;
        },
        eventsDetailRequestFailed: (misc, action) => {
            misc.loading = false;
        },
    },
});

export const {
    eventsRequested,
    eventsReceived,
    eventsRequestFailed,
    eventsFeatureRequested,
    eventsfeatureReceived,
    eventsFeatureRequestFailed,
    eventsDetailRequested,
    eventsDetailReceived,
    eventsDetailRequestFailed,
    myEventsRequested,
    myEventsReceived,
    myEventsRequestFailed

} = slice.actions;
export default slice.reducer;

// Action Creators
const eventUrl = "events/";

export const searchEvents = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "search",
            params,
            method: "GET",
            onStart: eventsRequested.type,
            onSuccess: eventsReceived.type,
            onError: eventsRequestFailed.type,
            callback
        })
    );
};
export const searchEventsFeatured = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "search",
            params,
            method: "GET",
            onStart: eventsFeatureRequested.type,
            onSuccess: eventsfeatureReceived.type,
            onError: eventsFeatureRequestFailed.type,
            callback
        })
    );
};



export const searchMyEvents = (params, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "me",
            params,
            method: "GET",
            onStart: myEventsRequested.type,
            onSuccess: myEventsReceived.type,
            onError: myEventsRequestFailed.type,
            callback
        })
    );
};

export const getEventsDetail = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "details/" + id,
            method: "GET",
            onStart: eventsDetailRequested.type,
            onSuccess: eventsDetailReceived.type,
            onError: eventsDetailRequestFailed.type,
            callback
        })
    );
};

export const uploadCoverImage = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "uploadCoverImage",
            method: "POST",
            data,
            callback,
        })
    );
};

export const addEvent = (data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl,
            method: "POST",
            data,
            callback,
        })
    );
};

export const deleteEvent = (id, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + id,
            method: "DELETE",
            callback,
        })
    );
};

export const editEvent = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + id,
            method: "PUT",
            data,
            callback,
        })
    );
};

export const uploadVideo = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "uploadVideo/" + id,
            method: "POST",
            data,
            callback,
        })
    );
};

export const deleteVideo = (eventId, vidId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "removeVideo/" + eventId + "/" + vidId,
            method: "DELETE",
            callback,
        })
    );
};

export const uploadImages = (id, data, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "uploadImage/" + id,
            method: "POST",
            data,
            callback,
        })
    );
};

export const deleteImage = (eventId, vidId, callback) => (dispatch) => {
    return dispatch(
        apiCallBegan({
            url: eventUrl + "removeImage/" + eventId + "/" + vidId,
            method: "DELETE",
            callback,
        })
    );
};


export const getEvents = createSelector(
    (state) => state.entities.events,
    (events) => events
);
