import React, { Component } from "react";
import Cropper from "react-easy-crop";
import { Modal } from "react-bootstrap";
import { getCroppedImg } from "../../utils/canvasUtils";
import axios from "axios";
import AlertError from "../common/alert/alertError";
import { toast } from "react-toastify";
import loadingIcon from "../../include/images/loading-icon-gray.svg";

class ImageCropModel extends Component {
  state = {
    zoom: 1,
    loading: false,
    crop: { x: 0, y: 0 },
    croppedAreaPixels: "",
  };
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };
  showCroppedImage = async (e) => {
    this.setState({ loading: true });
    //ref.current.continuousStart();
    e.preventDefault();
    try {
      const croppedImage = await getCroppedImg(
        this.props.image,
        this.state.croppedAreaPixels
      );
      const data = croppedImage;
      let formData = new FormData();

      formData.append("image", this.dataURItoBlob(data));
      axios
        .post(
          process.env.REACT_APP_APIBASE + "users/uploadProfilePicture",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              jwt: localStorage.getItem("x-auth-token"),
              Authorization: process.env.REACT_APP_TOKEN,
            },
          }
        )
        .then(() => {
          this.props.loadProfile();
          this.setState({ loading: false });
          this.props.toggleCrop();
        })
        .catch(() => {
          //ref.current.complete();
          toast(
            <AlertError message={"Error while uploading profile picture."} />
          );
          this.setState({ loading: false });
          this.props.toggleCrop();
        });
    } catch (e) {
      toast(<AlertError message={"Error while uploading profile picture."} />);
    }
  };
  render() {
    return (
      <Modal
        className={
          this.props.profilePage
            ? "right fade profile-notification-modal"
            : "modal fade process-popup crop-popup verification-popup"
        }
        dialogClassName="modal-dialog modal-dialog-centered"
        show={this.props.cropperModal}
        onHide={this.props.toggleCrop}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Crop</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "400px" }}>
          <p>
            <Cropper
              image={this.props.image}
              crop={this.state.crop}
              zoom={this.state.zoom}
              aspect={4 / 4}
              onCropChange={(e) => this.setState({ crop: e })}
              onCropComplete={this.onCropComplete}
              showGrid={false}
            />
          </p>
        </Modal.Body>
        <div className="crop-zoom d-flex align-items-center p-3">
          <label
            for="customRange1"
            className="form-label"
            style={{ marginBottom: "0", marginRight: "7px" }}
          >
            {"Zoom"}
          </label>
          <input
            min="1"
            max="7"
            step="0.05"
            type="range"
            Name="form-range"
            id="customRange1"
            value={this.state.zoom}
            onChange={(e) => this.setState({ zoom: e.target.value })}
            style={{ width: "100%" }}
          />
        </div>
        <Modal.Footer>
          {!this.state.loading ? (
            <a
              href="javascript:void(0)"
              onClick={this.showCroppedImage}
              className="btn btn-default"
            >
              {"Crop"}
            </a>
          ) : (
            <div className="icon">
              <img src={loadingIcon} alt="" className="loader-img fa-spin" />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ImageCropModel;
